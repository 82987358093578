import { QuanAdvantage, QuanLicense, QuanSystem, QuanTrading } from "@/components";


export const quantitative = [
    {
        label: "What is quantitative trading?",
        content: <QuanTrading />
    },

    {
        label: "What is AIELATE intelligent quantitative trading system?",
        content: <QuanSystem />
    },

    {
        label: "What is advantage of AIELATE intelligent quantitative trading?",
        content: <QuanAdvantage />
    },
    {
        label: "AIELATE business license",
        content: <QuanLicense />
    },
]