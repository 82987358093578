const Quan_System = () => {
  return (
    <div className="user_terms">
      <ul>
        <li>
          AIELATE is an intelligent cryptocurrency trading system based on
          blockchain. It is developed and supported by the professional
          blockchain technology team of AIELATE Company. The intelligent trading
          mode with 100% accuracy and zero risk rate of the system is trusted
          and chosen by investment enthusiasts around the world.
        </li>
        <li>
          AIELATE intelligent trading system has gone through 3 years of market
          research, software development, testing and verification process from
          the initial project incubation to successful launch, and was
          officially launched for global operation in 2017.
        </li>
        <li>
          The intelligent trading system will effectively improve the various
          drawbacks and problems existing in manual trading, and simplify the
          tedious steps in manual trading, greatly improve the efficiency of
          trading, but also for users to easily achieve a stable state of wealth
          appreciation.
        </li>
        <li>
          The 24-hour uninterrupted trading operation comprehensively covers the
          major trading markets around the world, and real-time analysis of the
          trading data of popular currencies is conducted to select the best
          trading opportunities and ensure that each transaction can achieve
          profitability.
        </li>
        <li>
          At its core, the idea is to buy a cryptocurrency at low price and sell
          it at high price. The difference in exchange rate is the profit of
          each complete transaction (buying and selling)
        </li>
        <li>
          For example, if user buys $10,000 of USDT on Coinbase and then sells
          it on Binance for $11,000, the $1,000 exchange rate difference
          generated by the transfer transaction is the trading profit
        </li>
        <li>
          In simple terms, it means buying cryptocurrency at a low price and
          selling it at a high price to capitalise on the difference in the
          exchange rate
        </li>
        <li>Under the operation of the AIELATE intelligent trading system, the efficiency of science and technology completely releases the hands of users, helping users bID farewell to all the tedious processes in the traditional manual trading model; and users can easily realise the stable increase of wealth on a daily basis</li>
      </ul>
    </div>
  );
};

export default Quan_System;
