
import { Skeleton } from 'antd'
import Typography from 'antd/es/typography/Typography'
import React from 'react'

interface VIPGradesProps {
  data: UpgradeConditionsItem[];
  loading: boolean;
}

const VIP_Grades:React.FC<VIPGradesProps> = ({ data, loading }) => {
  return (
    <div className='quantify_vip_grades'>
        <Typography className='grade_position'>{data?.map((item) => item?.caption)}</Typography>
        <div className='grade_position_content'>
            {data?.map((item) => (
                <section className='grade_position_content_inner' key={item?.id}>
                <Typography>{item?.name}</Typography>
                <Typography>{loading ? <Skeleton.Button size='small' /> : item?.subName}</Typography>
            </section>
            ))}
        </div>
    </div>
  )
}

export default VIP_Grades
