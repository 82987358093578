
import React, { useEffect } from 'react'
import { Button, InputField } from '../ui'
import { Typography } from 'antd'
import { HelpCircle } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { appService } from '@/services'
import { Schema } from '@/schema/schema'
import { RootState } from '@/config/store'
import { toast } from 'react-toastify'

const Bind_Email = () => {
    const dispatch: ThunkDispatch<any, any, any> = useDispatch()
    const { isLoading, error } = useSelector((state: RootState) => state.bindEmail)
    const { loading, err } = useSelector((state: RootState) => state.requestEmailBind)
  

    useEffect(() => {
        if(err) {
            toast.error(err)
        }
        if(error) {
            toast.error(error)
        }
    }, [err, error])


    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
        },
        validationSchema: Schema.BindEmailSchema,
        onSubmit: (values) => {
            dispatch(appService.bindEmail(values.email, values.otp))
        }
    })


    const userEmail = formik.values.email

    // a function to send verification code
    const handleSendActivationCode = async () => {
        dispatch(appService.requestEmailBind(userEmail))
      };


  return (
    <div>
        <form onSubmit={formik.handleSubmit}>

        <section className='w-full'>
        <InputField 
            variant="borderless"
            size="large"
            type="email"
            placeholder="Enter your email address"
            name="email"
            label='Email Address'
            value={formik.values.email}
            onChange={formik.handleChange}
          />

        <InputField 
            variant="borderless"
            size="large"
            type="text"
            placeholder="Enter verification code"
            name="otp"
            label='OTP'
            value={formik.values.otp}
            onChange={formik.handleChange}
            suffix={<span className='auth_suffix' onClick={() => handleSendActivationCode()}>{ loading ? "sending..." : "Get Code"}</span>}
          />
        </section>
       
       <section className='bind_email_warning'>
            <span> <HelpCircle color={`var(--app-primary)`} /> Warning</span>
            <Typography>To further secure your email address, you need to complete the email verification process</Typography>
       </section>
        
        <Button variant="auth__btn" label="Confirm" color="primary" type="submit" loading={isLoading } />
    
        </form>
    </div>
  )
}

export default Bind_Email
