
import Logo from "./logo.png";
import Logo2 from "./logo.jpeg"
import Logo3 from "./logo3.png"
import DepositIcon from "./deposit.png";
import ActivityIcon from "./activity.png";
import DownloadIcon from "./download.png";
import CustomerIcon from "./customer.png";
import HelpIcon from "./help.png";
import InviteIcon from "./invite.png";
import WithdrawIcon from "./withdraw.png";
import TeamIcon from "./team.png"
import AIELATEBG from "./bytesi_news.jpeg"
import BTCIcon from "./btcIcon.png"
import TetherIcon from "./tetherIcon.png"
import WealthContentImg from "./wealth_content.png"
import G1 from "./g1.png"
import G2 from "./g2.png"
import G3 from "./g3.png"
import G4 from "./g4.png"
import G5 from "./g5.png"
import G6 from "./g6.png"
import Slide1 from "./slide1.jpeg"
import Slide2 from "./slide2.jpeg"
import Slide3 from "./slide3.jpeg"
import Slide4 from "./slide4.jpeg"
import Slide5 from "./slide5.jpeg"
import ETHIcon from "./ETHIcon.png"
import DOGEIcon from "./DOGEIcon.png"
import ADAIcon from "./ADA.png"
import AVAXIcon from "./AVAX.png"
import SOLIcon from "./SOL.png"


export const Images = {
    ADAIcon,
    AVAXIcon,
    SOLIcon,
    ETHIcon,
    DOGEIcon,
    Logo,
    Logo2,
    Logo3,
    DepositIcon,
    ActivityIcon,
    DownloadIcon,
    CustomerIcon,
    HelpIcon,
    InviteIcon,
    WithdrawIcon,
    TeamIcon,
    AIELATEBG,
    BTCIcon,
    WealthContentImg,
    G1,
    G2,
    G3,
    G4,
    G5,
    G6,
    TetherIcon,
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5
}