import { Collapse, CollapseProps } from "antd";

const Help = () => {
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "【AIELATE User Guide】",
      children: (
        <div className="user_terms">
          <ul>
            <li>
              Do not save your account number and password when logging in, do
              not log in automatically, remember your account number and
              password!
            </li>
            <li>
              Users are asked to protect their privacy and financial security.
            </li>
            <li>
              It is strictly prohibited to maliciously slander AIELATE, spread
              harmful remarks and other offensive behaviors!
            </li>
            <li>
              Malicious impersonation of AIELATE officials is prohibited. AIELATE
              staff will not ask customers to provide their wallet addresses and
              passwords. Please pay attention to the distinction!
            </li>
            <li>
              Carefully analyze user feedback and suggestions, and properly
              handle AIELATE user complaints and dissatisfaction.
            </li>
            <li>
              Improve platform experience and service quality, improve the
              platform, and increase AIELATE’s popularity and reputation!
            </li>
            <li>
              AIELATE promises to keep all users’ registration information and
              private information absolutely confidential. AIELATE provides the
              greatest security for user registration information. At the same
              time, users must keep passwords, personal accounts and other
              information confidential to avoid being stolen or tampered with.
              In addition, if you discover any illegal use of your user account
              or security breach, please contact the AIELATE online administrator
              immediately.
            </li>
            <li>
              AIELATE protects its legitimate rights and interests in accordance
              with the law, and the final interpretation rights and all terms
              belong to AIELATE
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "2",
      label: "【Platform Rules】",
      children: (
        <div className="user_terms">
          <ul>
            <li>
              AIELATE prohibits malicious opening of multiple accounts, and it is
              strictly prohibited to use false information to register AIELATE.
            </li>
            <li>
              AIELATE strictly prohibits the use of fraudulent means to obtain
              bonuses. The system will be checked from time to time. If a team
              is found to have maliciously violated AIELATE rules, they will be
              punished accordingly. In serious cases, the account will be
              permanently frozen!
            </li>
            <li>
              One wallet can only be used for one AIELATE account, and one wallet
              is prohibited from using multiple AIELATE accounts.
            </li>
            <li>
              Multiple people are prohibited from using one AIELATE account at
              the same time!
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "3",
      label:
        "Common contact details for official AIELATE  online work instructions",
      children: (
        <div className="user_terms">
          <ul>
            <li>Telegram:https://t.me/AIELATE _Pro77</li>
          </ul>
        </div>
      ),
    },
    {
      key: "4",
      label: "How to recharge",
      children: (
        <div className="user_terms">
          <ul>
            <li>Click the recharge</li>
            <li>Enter the amount</li>
            <li>Click the TRC20</li>
            <li>Copy the address</li>
            <li>Make the transfer complete to deposit</li>
          </ul>
        </div>
      ),
    },
    {
      key: "5",
      label: "How to withdraw",
      children: (
        <div className="user_terms">
          <ul>
            <li>Click to withdraw</li>
            <li>Enter the TRC20 withdrawal address</li>
            <li>Enter the amount</li>
            <li>Set a withdrawal password</li>
            <li>Enter the withdrawal password to complete of withdrawal</li>
          </ul>
        </div>
      ),
    },

    {
      key: "6",
      label: "How to change login password",
      children: (
        <div className="user_terms">
          <ul>
            <li>Click the login password</li>
            <li>Set a new login password and confirm the password</li>
          </ul>
        </div>
      ),
    },

    {
      key: "7",
      label: "How to change transaction password",
      children: (
        <div className="user_terms">
          <ul>
            <li>Click the transaction password</li>
            <li>Set a new login password and confirm the password</li>
          </ul>
        </div>
      ),
    },
    {
      key: "8",
      label: "How to share",
      children: (
        <div className="user_terms">
          <ul>
            <li>Click ton invite</li>
            <li>
              Copy the registration link and send it to a friend for complete
              the shared
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "9",
      label: " AIELATE 's withdrawal instructions",
      children: (
        <div className="user_terms">
          <ul>
            <li>
              If there have $1,000 flexible fund in account and you want to
              withdraw all $1,000, so the $1,000 cannot be used to execute
              quantitative order
            </li>
            <li>
              If there have $1,000 flexible fund in account and you want to
              withdraw a portion of it, this amount shall not be used to execute
              quantitative orders.{" "}
            </li>
            <p>For Example:</p>
            <p>
              If you want to withdraw $300 of it, you have to withdraw the $300
              before you execute the quantitative order, or you can only use the
              $700 of it to execute the quantitative order, so the remaining
              $300 can be withdraw
            </p>
            <li>
              If there have $1,000 flexible fund in account, and the flexible
              fund has been used for execute the quantitative order, the fund
              will switch from the flexible state to the waiting be released
              state. After the system time is updated at 24pm in Colorado, the
              fund can be withdrawn after it becomes flexible again
            </li>
            <li>
              In order to strictly protect the security of funds, if changed the
              withdrawal address and password, it's must be withdraw the funds
              after the fund protection status be ended at after 48 hours
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "10",
      label: "AIELATE  valid user description",
      children: (
        <div className="user_terms">
          <ul>
            <li>
              After you recharge your AIELATE account with US$35, you will be
              immediately upgraded to a valid member
            </li>
            <li>
              Valid users can permanently enjoy the qualification to withdraw
              coins.
            </li>
            <li>Valid users can conduct quantitative trading permanently</li>
            <li>Valid users can permanently receive team commissions</li>
            <li>
              Additional bonuses and benefits for you (see current affairs)
            </li>
            <li>
              Lower-level team members (Lv1) must recharge to a total of 100
              USDT and bind their email address and wallet address to be
              eligible for promotion, and their account balance must remain
              above 100 USDT.
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "11",
      label: "AIELATE  Corporation Company Profile",
      children: <div className="user_terms">
        <ul>
            <li>AIELATE Corporation Company Profile AIELATE Corporation was founded in 2017 and is located in Carson City, Nevada, USA. The full name of the company is Byte Intelligent Quantitative Technology Investment Co., Ltd. Committed to the development, innovation, transaction services, asset custody, and network security operations of cryptocurrency worldwide. With its mature business model and core technology, AIELATE has achieved achievements in the blockchain field that only a few companies can match. Over the years, it has also won multiple technological innovation awards from the “Blockchain Alliance”.</li>
            <li>Since its establishment, AIELATE has steadily increased its share of the global cryptocurrency investment market, constantly breaking historical records and creating business miracles again and again. Up to now, AIELATE has more than 1 million users around the world, with a peak daily average of 300,000 active users. ByteDance has become a well-known company in the global blockchain field. It has not only promoted the rapid development of the global blockchain industry, but also become a pioneer in the innovation revolution in this field.</li>
            <li>After 4 years of steady development and growth, ByteDance has become a well-known enterprise in the global blockchain field, showing strong competitiveness in the industry, and its market share continues to set new records. With the continuous improvement of comprehensive strength and business expansion, AIELATE Group was established in 2024.</li>
            <li>"Let technology illuminate the light of life" is not only ByteDance's corporate slogan, but also ByteDance's mission. Bitcoin adheres to the core strategic policy of "service first, technology creates the future" and is committed to developing an intelligent quantitative trading system for cryptocurrency with distinctive "Bitcoin company characteristics", aiming to improve the efficiency and accuracy of each user's cryptocurrency transactions. , eliminate misjudgments in transactions. Get rid of cumbersome manual transaction processes and maximize benefits scientifically.</li>
            <li>Such impressive results are the result of the day-to-day efforts and dedication of all employees, which have repeatedly created business miracles for Bytes. No matter now or in the future, ByteDance is committed to working with all members to set sail again and move towards higher achievements and glory.</li>
            <li>With the rapid development of blockchain on a global scale, in the near future, the trend of smart cryptocurrency trading will sweep the global cryptocurrency investment market and become the first choice for global cryptocurrency investors. AIELATE's intelligent quantitative trading system will also become a leading indicator in the global blockchain field.</li>
            <li>Introduction to the business scope of AIELATE Corporation AIELATE Corporation is headquartered in Colorado, USA. , expanding its business scope to the entire United States and North American regional markets. With the widespread adoption of 4G technology around the world and the emergence of 5G technology, blockchain technology and cryptocurrency investment are accelerating promotion and coverage globally. To date, AIELATE has achieved complete market coverage in North America and Europe, and occupies an important position in many major economies in Asia, South America and the Middle East.</li>
            <li>AIELATE Corporation aims to provide global cryptocurrency investors with the most scientific and reasonable intelligent financial management methods, as well as professional and secure digital asset trading and custody services.</li>
            <li>There are many digital currency exchanges in the world, each with the ability to convert fiat currencies into digital currencies. The price for converting fiat currencies into digital currencies varies from exchange to exchange, allowing people to take advantage of exchange rate differences to buy low and sell high.</li>
            <li>Manually buying at a lower price and selling at a higher price on multiple exchanges is very time consuming. When prices fluctuate, operations and transactions may take a long time, possibly missing the best exchange rate and reducing trading profits.</li>
            <li>Therefore, AIELATE’s automatic quantification system seems to avoid these manual problems and improve the profitability of quantitative trading. AIELATE's automatic quantification system can automatically search for the lowest selling price of digital currencies such as BTC, ETH, USDT and other major exchanges, and quickly purchase them within seconds. When the price reaches the set selling point, the system will execute a quick sale within seconds. It only takes a few minutes to automatically complete the process of buying low and selling high, and quickly complete quantitative transactions. 24/7 data collection eliminates the need for manual market monitoring to achieve efficient and stable revenue generation - this is the AIELATE automatic quantification system.</li>
        </ul>
      </div>,
    },
    {
      key: "12",
      label: `AIELATE 's Operation specification?`,
      children: <div className="user_terms">
        <ul>
            <li>AIELATE has more than 1 million users around the world, and its business scope covers many countries. Achieve full network coverage and support Android, Apple, and PC. Currently, the same device and network IP can host up to two AIELATE accounts. The system operates based on GMT-8, Carson City, Nevada, USA, and operates continuously 24 hours a day. The funds for executing quantitative orders every day will be refreshed and reset at 12:00 a.m. in Carson City, Nevada, USA (GMT-8), so that they can be reused when executing quantitative orders.</li>
            <li>Therefore, AIELATE’s automatic quantification system seems to avoid these manual problems and improve the profitability of quantitative trading. AIELATE's automatic quantification system can automatically search for the lowest selling price of digital currencies such as BTC, ETH, USDT and other major exchanges, and quickly purchase them within seconds. When the price reaches the set selling point, the system will execute a quick sale within seconds. It only takes a few minutes to automatically complete the process of buying low and selling high, and quickly complete quantitative transactions. 24/7 data collection eliminates the need for manual market monitoring to achieve efficient and stable revenue generation - this is the AIELATE automatic quantification system.</li>
        </ul>
      </div>,
    },
    {
      key: "13",
      label: `AIELATE  Business licence enquiry guide?`,
      children: <div className="user_terms">
        <ul>
            <p>Company name: AIELATE</p>
            <p>Nature of business: financial technology</p>
            <p>Legal name: Montague Sterling</p>
            <p>Registered capital: US$50 million</p>
            <p>Registered address: 300 S 4th St, Las Vegas, NV 89101 USA</p>
            <p>Country/State of Registration: Nevada, USA</p>
            <br />
            <li>Therefore, AIELATE’s automatic quantification system seems to avoid these manual problems and improve the profitability of quantitative trading. AIELATE's automatic quantification system can automatically search for the lowest selling price of digital currencies such as BTC, ETH, USDT and other major exchanges, and quickly purchase them within seconds. When the price reaches the set selling point, the system will execute a quick sale within seconds. It only takes a few minutes to automatically complete the process of buying low and selling high, and quickly complete quantitative transactions. 24/7 data collection eliminates the need for manual market monitoring to achieve efficient and stable revenue generation - this is the AIELATE automatic quantification system.</li>
        </ul>
      </div>,
    },
  ];

  return (
    <div>
      <Collapse accordion items={items} expandIconPosition="end" />
    </div>
  );
};

export default Help;
