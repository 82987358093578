import { Images } from '@/assets/images'
import { paths } from '@/constants'
import { BarChart3, HomeIcon, User, User2Icon, Users, Wallet } from 'lucide-react'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const DashboardLayout:React.FC<LayoutProps> = ({children, logo, title, label}) => {
    const location = useLocation();
    const navigate = useNavigate()

    const nav = [
        {   
            id: 1,
            icon: <HomeIcon />,
            label: "Home",
            path: paths.home
        },

        {
            id: 2,
            icon: <BarChart3 />,
            label: "Quantify",
            path: paths.quantify
        },

        {
            id: 3,
            icon: <Users />,
            label: "Team",
            path: paths.team
        },

        {
            id: 4,
            icon: <Wallet />,
            label: "Assets",
            path: paths.assets
        },

        {
            id: 5,
            icon: <User />,
            label: "Profile",
            path: paths.profile
        },
    ]
  return (
    <div className='wrapper'>
        <div className='layout_top'>
            <section> 
                {logo ? <img src={Images.Logo2} alt='' width={30} height={30} /> : null}
                <span>{title}</span>
            </section>
            <span className="top_icon">{label ? label :  <User2Icon onClick={() => navigate(paths.profile)} />}</span>
        </div>
     
        <div className='layout_scroll'>
            {children}
        </div>
      <div className='layout_footer'>
            <div className='layout_nav_wrapper'>
                {nav.map((nav) => (
                    <Link to={nav.path} key={nav?.id}>
                            <section className={location.pathname === nav?.path ? "layout_nav_active" : "layout_nav"}>
                        {nav?.icon}
                        <span>{nav?.label}</span>
                    </section>
                    </Link>
                ))}
            </div>
      </div>
    </div>
  )
}

export default DashboardLayout
