import React from 'react'
import { Routes, Route } from "react-router-dom"
import { ChangePassword, DashboardAssets, DashboardProfile, DashboardQuantify, DashboardTeam, HomeIndex, LoginAuth, RegisterAuth } from './pages'
import { paths } from '@/constants/paths'
import { Authorized } from './api/authorized'
import { useSelector } from 'react-redux'
import { RootState } from './config/store'

const AppRoutes = () => {
  const { token } = useSelector((state: RootState) => state.auth)
  const accessToken = token;

  return (
    <>
      <Routes>
          <Route path={paths.login} index element={<LoginAuth />} />
          <Route path={paths.register} index element={<RegisterAuth />} />
          <Route path={paths.changePassword} index element={<ChangePassword />} />
          <Route element={<Authorized accessToken={accessToken?.token} />}>
            <Route path={paths.home} index element={<HomeIndex />} />
            <Route path={paths.profile} element={<DashboardProfile />} />
            <Route path={paths.assets} element={<DashboardAssets />} />
            <Route path={paths.quantify} element={<DashboardQuantify />} />
            <Route path={paths.team} element={<DashboardTeam />} />
          </Route>

      </Routes>
    </>
  )
}

export default AppRoutes