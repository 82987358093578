import { Images } from "@/assets/images";
import { Carousel, DashboardLayout, ProfileModal, Tabs } from "@/components";
import WhatsAppMessenger from "@/components/ui/WhatsAppMessenger";
import { paths } from "@/constants";
import { activities, homeTabs, quantitative } from "@/data";
import { ChevronRight, Volume2 } from "lucide-react";
import { useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalContent, setModalContent] = useState<any>(null);
  const [ isHeight, setIsHeight ] = useState<string | number>("");
  const [ modalVisible, setModalVisible] = useState<boolean>(false);
  const [ isModalContent, setIsModalContent ] = useState<any>(null);
  const [ isModalTitle, setIsModalTitle] = useState<string>("");

  const imageList = [Images.Slide1, Images.Slide2, Images.Slide3, Images.Slide4, Images.Slide5];

  // a function to open each profile details modal
  const handleHomeActivityTabClick = (content: any, label: string, height: string | number) => {
    setModalContent(content);
    setModalTitle(label);
    setIsModalVisible(true);
    setIsHeight(height)
  };

  const handleHomeAdvantage = (content: any, label: string) => {
    setIsModalContent(content);
    setIsModalTitle(label);
    setModalVisible(true);
  };

  return (
    <DashboardLayout logo={true} title="AIELATE Quantification">
      <div className="home_activity">
        {activities?.map((item, i) => (
          <section
            key={i}
            onClick={() => {
              if (item?.path !== null) {
                navigate(item?.path);
              } else if (item?.content !== null) {
                handleHomeActivityTabClick(item.content, item.label, item.height);
              }
            }}
          >
            <img src={item?.img} alt="" width={28} height={28} />
            <span>{item?.label}</span>
          </section>
        ))}
      </div>

      {/* moving information */}
      <div className="home_marquee">
        <Volume2 />
        <Marquee play pauseOnHover={true} direction="left">
          {paths.message}
        </Marquee>
      </div>

      {/* intro video */}
      <Carousel images={imageList} />

      {/* quantitative advantage */}
      <div className="home_quant_wrapper">
        <section className="title">
          <span>AIELATE Quantitative Advantage</span>
        </section>
        <div className="home_quant">
          {quantitative?.map((item, i) => (
            <section
              key={i}
              onClick={() => handleHomeAdvantage(item?.content, item?.label)}
            >
              <span>{item?.label}</span>
              <ChevronRight />
            </section>
          ))}
        </div>
      </div>
      
      {/* home flag message */}
      <div className="home_message_news">
        <img src={Images.AIELATEBG} alt="" />
      </div>

      {/* custom tab */}
      <Tabs tabs={homeTabs} />

      {modalContent !== null && (
        <ProfileModal
          open={isModalVisible}
          handleClose={() => setIsModalVisible(false)}
          title={modalTitle}
          newHeight={isHeight}
        >
          {modalContent}
        </ProfileModal>
      )}

        {isModalContent !== null && (
          <ProfileModal
            open={modalVisible}
            handleClose={() => setModalVisible(false)}
            title={isModalTitle}
            newHeight="600px"
          >
            {isModalContent}
          </ProfileModal>
        )}
        <WhatsAppMessenger />
    </DashboardLayout>
  );
};

export default Home;
