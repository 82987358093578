import { CopyIcon } from "lucide-react";
import React, { useEffect } from "react";
import { InputField } from "../ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/config/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";
import { handleCopyClick } from "@/utils/Utils";
import QRCode from "react-qr-code";

const Invite_Friend = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { info } = useSelector((state: RootState) => state.info);

  useEffect(() => {
    dispatch(appService.fetchUserInfo());
  }, [dispatch]);

  return (
    <div className="home_invite_friends">
      <section className="code_url">
        <InputField
          type="text"
          variant="borderless"
          value={`${window.location.hostname}/auth/register/?inviteCode=${info?.profile?.invitationCode}`}
          disabled={true}
        />
        <span
          onClick={() =>
            handleCopyClick(
              `${window.location.hostname}/auth/register/?inviteCode=${info?.profile?.invitationCode}`
            )
          }
        >
          <CopyIcon size={50} color={`var(--app-yellow)`} />
        </span>
      </section>

      <div className="bar_code_reader">
        <QRCode
          size={200}
          value={`${window.location.hostname}/auth/register/?inviteCode=${info?.profile?.invitationCode}`}
        />
      </div>

      <section className="home_invite_friends_code">
        <span>
          Invitation Code: <b>{info?.profile?.invitationCode}</b>{" "}
        </span>
        <span onClick={() => handleCopyClick(info?.profile?.invitationCode)}>
          <CopyIcon color={`var(--app-yellow)`} />
        </span>
      </section>
    </div>
  );
};

export default Invite_Friend;
