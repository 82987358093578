



import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';


interface UserDataProps {
    success: boolean;
    message: string
}

interface RegisterState {
  isLoading: boolean;
  error: string | null;
  bindEmail: UserDataProps | null;
}

const initialState: RegisterState = {
  isLoading: false,
  error: null,
  bindEmail: null,
};

const registerSlice = createSlice({
  name: 'bindEmail',
  initialState,
  reducers: {
    bindStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    bindSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.bindEmail = action.payload;
    },
    bindFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { bindStart, bindSuccess, bindFailure } = registerSlice.actions;

export const bindEmail = (email: string, otp: string) => async (dispatch: any) => {
  try {
    dispatch(bindStart());
    const response = await userRequest.post(`/user/email-bind`, {email, otp});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(bindSuccess(data));
      toast.success(message);
    } else {
      dispatch(bindFailure(message))
    }
  } catch (error: any) {
    let errorMessage = '';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = error.message 
    }
    dispatch(bindFailure(errorMessage))
  }
};

export default registerSlice.reducer;
