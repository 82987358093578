


import { userRequest } from '@/api/connect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


interface TasksProps {
    success: string;
    message: string;
}

interface TasksState {
  doTask: TasksProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: TasksState = {
  doTask: null,
  loading: false,
  error: null,
};

const doTaskSlice = createSlice({
  name: 'doTask',
  initialState,
  reducers: {
    DoTaskStart(state) {
      state.loading = true;
      state.error = null;
    },
    DoTaskSuccess(state, action: PayloadAction<TasksProps>) {
      state.doTask = action.payload;
      state.loading = false;
      state.error = null;
    },
    DoTaskFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { DoTaskStart, DoTaskSuccess, DoTaskFailure } = doTaskSlice.actions;

export const doTask = () => async (dispatch: any) => {
  try {
    dispatch(DoTaskStart());
    const response = await userRequest.get(`/user/task`);
    const { data, success, message } = response.data
    if(success === true) {
        dispatch(DoTaskSuccess(data));
        toast.success(message);
    } else {
      dispatch(DoTaskFailure(message))
    }
  } catch (error: any) {
    dispatch(DoTaskFailure(error.message));
  }
};

export default doTaskSlice.reducer;
