import { Skeleton } from "antd";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface DataItem {
  category: string;
  values: number[];
}

interface RadarChartProps {
  data: DataItem[];
  loading: boolean;
}

interface SeriesItem {
  name: string;
  data: number[];
}
const Gene_Chart: React.FC<RadarChartProps> = ({ data, loading }) => {
  let series: SeriesItem[] = [];

  if (data) {
    series = Array.from({ length: 3 }, (_, index) => ({
      name: `Gen ${index + 1} Data`,
      data: data.map((item) => (item.values && item.values[index]) || 0),
    }));
  }

  const categories = data?.map((item) => item?.category) || [];


  // Flatten the array of arrays into a single array
const flattenedValues = data
  .map(item => item?.values)
  .flat();

// Find the maximum value
const highestValue = Math.max(...flattenedValues);


  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "radar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      stepSize: highestValue,
    },
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 5,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: -90,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      x: {
        show: true,
      }
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton paragraph={{ rows: 3 }} />
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="radar"
          height={350}
        />
      )}
    </>
  );
};

export default Gene_Chart;
