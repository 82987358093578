export const AuthPrefix = "/";
export const DashboardPrefix = "/dashboard";

export const paths = {
  login: AuthPrefix,
  register: AuthPrefix + "auth/register",
  changePassword: AuthPrefix + "auth/reset/password",
  home: DashboardPrefix + "/home",
  quantify: DashboardPrefix + "/quantify",
  assets: DashboardPrefix + "/assets",
  team: DashboardPrefix + "/team",
  deposit: DashboardPrefix + "/deposit",
  profile: DashboardPrefix + "/profile",

  message: `Congratulations to 108132 for being promoted to VIP.1          Welcome 112491 to join AIELATE           Congratulations to 110697 for depositing 1498.538128USDT          Congratulations to 105681 for withdrawing 520USDT          Congratulations to 108132 for being promoted to VIP.1          Welcome 112491 to join AIELATE           Congratulations to 109332 for being promoted to VIP.1          Welcome 112483 to join AIELATE           Welcome 112477 to join AIELATE           Welcome 112468 to join AIELATE           Welcome 112459 to join AIELATE           Congratulations to 112454 for being promoted to VIP.1          Welcome 112454 to join AIELATE           Congratulations to 112032 for being promoted to VIP.1          Congratulations to 107023 for being promoted to VIP.1          Welcome 112448 to join AIELATE           Congratulations to 112440 for being promoted to VIP.1          Welcome 112440 to join AIELATE           Welcome 112433 to join AIELATE           Welcome 112427 to join AIELATE           Congratulations to 112422 for being promoted to VIP.1          Welcome 112422 to join AIELATE           Welcome 112416 to join AIELATE           Congratulations to 112410 for being promoted to VIP.1          Welcome 112410 to join AIELATE           Welcome 112403 to join AIELATE           Welcome 112396 to join AIELATE           Welcome 112390 to join AIELATE           Welcome 112385 to join AIELATE           Congratulations to 112242 for being promoted to VIP.1          Welcome 112380 to join AIELATE           Congratulations to 112361 for being promoted to VIP.1          Congratulations to 105798 for being promoted to VIP.2          Welcome 112373 to join AIELATE`,
};
