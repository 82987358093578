import { RootState } from "@/config/store";
import { appService } from "@/services";
import { handleCopyClick } from "@/utils/Utils";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {  Skeleton, Typography } from "antd";
import { Copy } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FundAccount from "./Fund_Account";
import { Button } from "../ui";

const Deposit = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { fundAddress, isLoading } = useSelector(
    (state: RootState) => state.fundAddress
  );
  const [addAddress, setAddAddress] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(appService.fetchAllFundingAddress());
  }, [dispatch]);

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  const handleSuccess = () => {
    setAddAddress(false);
    setSuccess(true);
  };

  return (
    <div>
      {isLoading ? (
        <>{addAddress === true ? null :  <Skeleton paragraph={{ rows: 8 }} />}</>
      ) : (
        <>
          {addAddress === true ? null : (
            <>
              <Typography className="deposit_fund_desc">
                Please, you're to make payment to any of the address before
                proceeding to submit the payment receipt. Scroll down to click on the submit receipt button
              </Typography>

              {fundAddress?.map((item) => (
                <div key={item?.uuid} className="wallet_address_binding">
                  <section>
                    <span>{item?.address?.slice(0, 15)}</span>
                    <span>{item?.name}</span>
                  </section>
                  <span className="main_net">{item?.mainnet}</span>
                  <section className="address_binding_action">
                    <Button
                      shape="circle"
                      onClick={() => handleCopyClick(item?.address)}
                      icon={<Copy size={15} color={`var(--app-yellow)`} />}
                    />
                  </section>
                </div>
              ))}

              <Button
                onClick={() => handleAddAddress()}
                variant="auth__btn deposit_fund_btn"
                color="primary"
                type="button"
                label="Submit Receipt"
                loading={isLoading}
                disabled={isLoading}
      />
            </>
          )}
        </>
      )}
      {addAddress && (
        <FundAccount onClose={() => handleAddAddress()} success={success} onSuccess={handleSuccess} />
      )}
    </div>
  );
};

export default Deposit;
