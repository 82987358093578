
export const Terms = [
    {
        list: `AIELATE  users must be at least 18 years. AIELATE  users has fully agree to and accept AIELATE  Business Operation Regulations through joining AIELATE . Under any circumstances, the daily work of all users must be based on the AIELATE  Business Operation Regulations.`
    },
    {
        list: `AIELATE  reserves the right to modify the Business Operation Regulations while necessary. In case of any change in AIELATE  Business Operation Regulations, users will be prompted to modify the contents on the important pages. If the user doesn't agree with the changed content, the user may cancel the relevant service on their self-initiative. If the user would continues to enjoy the service, it will be deemed to have accepted the change of the Terms of Service. AIELATE  will occasionally newly increased other services and Business Operation Regulations, and these other services are subject to the terms of service of different Business Operation Regulations. If the new Business Operation Regulations doesn't issue updated the terms and conditions of service, the current Business Operation Regulations shall continue to be using.`
    },
    {
        list: `The service scope of AIELATE  Business Operation Regulations includes: providing quantification order operation, providing quantification order related information, providing online payment and settlement services, etc. The service scope of AIELATE  Business Operation Regulation doesn't include providing computer terminals, auxiliary equipment, network and communication resources to registered members, etc.`
    },
    {
        list: `
        AIELATE  promised to comply with all users' registration information and privacy information absolutely confidential. AIELATE  provides maximum security for users' registration information. At the same time, users must keep their passwords, personal accounts and other information confidential to avoid theft or tampering. In addition, every users is fully responsible for all activities and events in themselves account. If you found any illegal use of user accounts or security breaches, please contact AIELATE  Online Manager immediately.`
    },
    {
        list: `
        In order to safeguard the legitimate rights and interests of users and avoid disputes caused by the discrepancy between the user's registration information and the real situation at the time of withdrawal, please fill in the personal information during registration in accordance with the principle of authenticity, comprehensive and accuracy. AIELATE  shall not assume any legal responsibility for any abnormal situation caused by the user's own reasons.`
    },
    {
        list: `
        In response to the call of regional market financial regulatory authorities by country and resolutely crack down on malicious money laundering and other illegal acts, AIELATE  system will strictly examine every funds and strictly ensure the safety of all users' funds.`
    },
    {
        list: `In order to guarantees the legitimate rights and interests of AIELATE  and all AIELATE  users from illegal infringement, the Corporation will takes legal measures to legal countermeasures against all violators and resolutely safeguard the legitimate rights and interests of the Corporation and all AIELATE  users.`
    },
    {
        list: `AIELATE  shall defend its legitimate rights and interests in accordance with the law. AIELATE  shall have the right of final interpretation of all terms and contents.`
    }
]