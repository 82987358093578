
import { Drawer, Typography } from "antd";
import React from "react";
import { X } from "lucide-react";


const ProfileModal: React.FC<{open: boolean; handleClose: () => void; children: any, title?: string, newHeight?: string | number | undefined }> = ({ open, handleClose,title, newHeight, children }) => {

  return (
    <Drawer
      open={open}
      placement="bottom"
      width={400}
      onClose={handleClose}
      closeIcon={false}
      height={newHeight ?? `inherit`}
    >
      <div className="country_select">
      <div className="drawer__caption">
        <Typography>{title}</Typography>
        <span onClick={handleClose}>
          <X />
        </span>
      </div>
      </div>
    
      <div className="country_number_codes">
        {children}
      </div>
    </Drawer>
  );
};

export default ProfileModal;
