import { countries } from "@/data/Countries";
import { Drawer, Typography } from "antd";
import React, { useState } from "react";
import InputField from "./Input_Field";
import { X } from "lucide-react";
import Empty from "./Empty";

const CountryCode: React.FC<{
  open: boolean;
  handleClose: () => void;
  onSelectCountry: (dialCode: string) => void;
}> = ({ open, handleClose, onSelectCountry }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSelectCountry = (dialCode: string) => {
    onSelectCountry(dialCode);
    handleClose();
  };
  const handleSearchTermChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredCountries = countries.filter((country) =>
    country?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Drawer
      open={open}
      placement="bottom"
      width={400}
      onClose={handleClose}
      closeIcon={false}
    >
      <div className="country_select">
      <div className="drawer__caption">
        <Typography>Select International Phone Code</Typography>
        <span onClick={handleClose}>
          <X />
        </span>
      </div>
      <section className="drawer__search">
        <InputField
          type="search"
          size="large"
          placeholder="search"
          variant="borderless"
          value={searchTerm}
          onChange={handleSearchTermChange}
          autoFocus={true}
        />
      </section>
      </div>
      

      <div className="country_number_codes">
      {filteredCountries.length === 0 ? (
        <Empty text="Currently no such area code" />
      ) : (
        filteredCountries.map((country, index) => (
          <div key={index} className="country__codes" onClick={() => handleSelectCountry(country?.dial_code)}>
            <span>{country?.flag}</span>
            <span>{country?.name} {`(${country?.dial_code})`}</span>
          </div>
        ))
      )}
      </div>
    </Drawer>
  );
};

export default CountryCode;
