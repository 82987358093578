export const getCurrencyFromIP = async () => {
  try {
    const response = await fetch('https://ipinfo.io/json');
    const data = await response.json();
    const countryCode = data.country;

    // Define a type for the currency map
    const currencyMap: { [key: string]: string } = {
      'US': 'USD',
      'NG': 'NGN',
    };

    return currencyMap[countryCode] || 'USD';
  } catch (error) {
    console.error(error);
    return 'USD'; 
  }
};
