





import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';
import { appService } from '..';


interface UserDataProps {
    success: boolean;
    message: string
}

interface AddAddressState {
  isLoading: boolean;
  error: string | null;
  addAddress: UserDataProps | null;
}

const initialState: AddAddressState = {
  isLoading: false,
  error: null,
  addAddress: null,
};

const addAddressSlice = createSlice({
  name: 'addAddress',
  initialState,
  reducers: {
    addAddressStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addAddressSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.addAddress = action.payload;
    },
    addAddressFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { addAddressStart, addAddressSuccess, addAddressFailure } = addAddressSlice.actions;

export const AddWalletAddress = (walletAddress: string, mainNet: string, addressName: string) => async (dispatch: any) => {
  try {
    dispatch(addAddressStart());
    const response = await userRequest.put(`/wallet/wallet-address`, {walletAddress, mainNet, addressName});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(addAddressSuccess(data));
      toast.success(message);
      dispatch(appService.fetchAllAddress());
    } else {
      dispatch(addAddressFailure(message))
    }
  } catch (error: any) {
    if(error.code === "ECONNABORTED") {
      dispatch(addAddressFailure(appStatus.wrong))
    } else {
      dispatch(addAddressFailure(error?.message))
    }

  }
};

export default addAddressSlice.reducer;
