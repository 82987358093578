
import { Images } from '@/assets/images'
import { AssetSummary, DashboardLayout, EmptyFile, FundAccount, ProfileModal, Withdraw } from '@/components'
import { RootState } from '@/config/store'
import { appService } from '@/services'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { Spin, Typography } from 'antd'
import { CircleDollarSign, Download, FileClock } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Assets = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch()
  const { info, isLoading } = useSelector((state: RootState) => state.info)
  const [ deposit, setDeposit ] = useState<boolean>(false)
  const [ withdraw, setWithdraw ] = useState<boolean>(false)
  const [ success, setSuccess ] = useState<boolean>(false);


  useEffect(() => {
    dispatch(appService.fetchUserInfo())
  },[dispatch])

  const handleSuccess = () => {
    setDeposit(false)
    setSuccess(true)
  }




  return (
    <DashboardLayout logo={true} title="AIELATE Wallet" label={<span className='top_icon'><span className='top_icon_label'>Introduction to Assets </span><CircleDollarSign size={20} /> </span>}>
          <AssetSummary wallet={info?.wallet} loading={isLoading} />

        {/* asset summary deposit and withdraw */}

            <div className='asset_deposit_withdraw'>
            <Typography className='deposit' onClick={() => setDeposit(true)}><Download color={`var(--app-yellow)`} /> Deposit</Typography>
            <Typography className='withdraw' onClick={() => setWithdraw(true)}><Download color={`var(--app-yellow)`} />Withdrawal</Typography>    
          </div>


          {/* assets list */}
          <div className='assets_container_list'>
            <section className='assets_list_title'>
                <Typography>Assets List</Typography>
                <FileClock color={`var(--app-gray)`} />
            </section>


              {/* lists */}
              {isLoading  ? <Spin fullscreen /> : info?.length === 0 ? <>
              <EmptyFile text="No Assets Available, please try again" />
              </>  : 
                (<>

                <div className='assets_list'>
                <section className='assets_list_left'>
                  <img src={Images.TetherIcon} alt="" width={40} height={40} />
                    <Typography className='list_lift_name'>
                      <span>USDT</span>
                      <span>Tether USDT</span>
                    </Typography>
                </section>

                <Typography className='list_lift_name_right'>
                      <span>{info?.wallet?.flexibleBalance}</span>
                      <span>≈ {info?.wallet?.flexibleBalance} USDT</span>
                    </Typography>
            </div>

                </>)
              }

          </div>

          {deposit && (
            <ProfileModal open={deposit} handleClose={() => setDeposit(false)} title='Deposit'>
                  <FundAccount success={success} onSuccess={handleSuccess} onClose={() => setDeposit(false)} />
            </ProfileModal>
          )}

          {withdraw && (
            <ProfileModal open={withdraw} handleClose={() => setWithdraw(false)} title='Withdraw'>
              <Withdraw />
            </ProfileModal>
          )}

    </DashboardLayout>
  )
}

export default Assets
