import { DashboardLayout, VIPLevels } from "@/components";
import { RootState } from "@/config/store";
import { viplevels } from "@/data";
import { appService } from "@/services";
import { formatAmount, useCurrentTime } from "@/utils/Utils";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Skeleton, Spin, Typography } from "antd";
import { Clock, FileClock } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


const Quantify = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { info, isLoading } = useSelector((state: RootState) => state.info);
  const { allTasks, loading } = useSelector((state: RootState) => state.allTasks)
  const { error } = useSelector((state: RootState) => state.doTask)
  const [countdown, setCountdown] = useState<number | null>(null);
  const [countdownActive, setCountdownActive] = useState<boolean>(false);

  const time = "2024-05-31T22:48:46.758Z"

  const { formattedDate, formattedTime } = useCurrentTime(time);
  

  useEffect(() => {
      dispatch(appService.fetchUserInfo())
      dispatch(appService.fetchAllTasks())
  },[dispatch])



  // to do task countdown function
  const startCountdown = () => {
    let count = 5;
    setCountdown(count);
    setCountdownActive(true);
  
    const countdownInterval = setInterval(() => {
      count--;
      setCountdown(count);
      if (count === 0) {
        clearInterval(countdownInterval);
        setCountdownActive(false);
        dispatch(appService.doTask());
        setTimeout(() => {
          dispatch(appService.fetchAllTasks());
          dispatch(appService.fetchUserInfo())
        }, 2000);
      }
    }, 1000);
  };
  

  useEffect(() => {
    if(error) {
      toast.error(error)
    }
  },[error])

  return (
    <DashboardLayout
      logo={true}
      title="AIELATE Quantification"
      label={
        <span className="top_icon">
          <span className="top_icon_label">Quantification Records</span>{" "}
          <FileClock size={20} />{" "}
        </span>
      }
    >

      {/* earning booster */}
      <div className="quantify_booster_container">
        <div className="quantify_booster_wrapper">
          <Typography className="booster_time">
            <Clock size={20} color={`var(--app-primary)`} /> {formattedDate} {formattedTime }
          </Typography>

          {/* inner booster */}
          <div className="booster_inner_wrapper">
          <div className="booster_inner">
                <section>
                    <Typography>{isLoading ? <Skeleton.Button size="small" /> : `${formatAmount(info?.wallet?.flexibleBalance)}/${formatAmount(info?.wallet?.flexibleBalance)}`}</Typography>
                    <Typography>Total Quantifiable Amount</Typography>
                </section>
                <section>
                    <Typography>{isLoading ? <Skeleton.Button size="small" /> : formatAmount(info?.wallet?.todayEarnings) ?? "0"}</Typography>
                    <Typography>Total Earning's</Typography>
                </section>
          </div>

          <div className="booster_inner">
                <section>
                    <Typography>{loading ? <Skeleton.Button size="small" /> : allTasks?.taskDone ?? "0/0"}</Typography>
                    <Typography>Total Quantifiable Counts</Typography>
                </section>
                <section>
                    <Typography>{isLoading ? <Skeleton.Button size="small" /> : "0" }</Typography>
                    <Typography>AIELATE Earning</Typography>
                </section>
          </div>
          </div>
        </div>

        {/* booster accelerator */}
        <div className="booster_accelerator">
            <Typography>AIELATE Earning Booster</Typography>
            <section>
              {/* <Typography>Select Accelerator Pack</Typography>
              <ChevronRight size={20} color={`var(--app-gray)`} /> */}
            </section>
        </div>
      </div>

      {/* single start AIELATE QUANTIZATION */}
      <Typography className="start_AIELATE" onClick={startCountdown}>
        {countdown ? <><Spin spinning={countdownActive} fullscreen tip="loading..." /> <strong>{countdown}</strong> </> : 'Single Start AIELATE QUANTIZATION'}
      </Typography>

      {/* vip levels */}
      <VIPLevels data={viplevels} />      
    </DashboardLayout>
  );
};

export default Quantify;
