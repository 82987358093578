


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';
import { appService } from '..';


interface UserDataProps {
    success: boolean;
    message: string
}

interface EditAddressState {
  isLoading: boolean;
  error: string | null;
  editAddress: UserDataProps | null;
}

const initialState: EditAddressState = {
  isLoading: false,
  error: null,
  editAddress: null,
};

const editAddressSlice = createSlice({
  name: 'editAddress',
  initialState,
  reducers: {
    editAddressStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    editAddressSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.editAddress = action.payload;
    },
    editAddressFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { editAddressStart, editAddressSuccess, editAddressFailure } = editAddressSlice.actions;

export const EditWalletAddress = (walletAddress: string, mainNet: string, addressName: string, id: number) => async (dispatch: any) => {
  try {
    dispatch(editAddressStart());
    const response = await userRequest.patch(`/wallet/wallet-address`, {walletAddress, mainNet, addressName, id});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(editAddressSuccess(data));
      toast.success(message);
      dispatch(appService.fetchAllAddress());
    }
  } catch (error: any) {
    if(error.code === "ECONNABORTED") {
        dispatch(editAddressFailure(appStatus.wrong))
    } else {
        dispatch(editAddressFailure(error?.message))
    }

  }
};

export default editAddressSlice.reducer;
