


// App Inputs Schema

import * as yup from "yup";

// create Tournament Schema
export const LoginSchema = yup.object({
    countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    password: yup.string().required("Password is required"),
});


export const RegisterSchema = yup.object({
    countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    invitationCode: yup.string().required("Invitation Code is required"),
    password: yup.string().required("Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+_-])[A-Za-z\d@$!%*?&#+_-]{8,}$/,
      "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    confirmPassword: yup.string().required("Password confirmation is required").oneOf([yup.ref('password')], "Password do not match"),
    policy: yup.boolean().required("Policy is required"),
});

export const BindEmailSchema = yup.object({
    email: yup.string().required("Email is required"),
    otp: yup.string().required("OTP"),
});

export const ChangePasswordSchema = yup.object({
    newPassword: yup.string().required("New Password is required"),
    oldPassword: yup.string().required("Old Password is required"),
});

export const AddWalletAddressSchema = yup.object({
    walletAddress: yup.string().required("Wallet Address is required"),
    mainNet: yup.string().required("Main Net is required"),
    addressName: yup.string().required("Address Name is required"),
});

export const PassCodeSchema = yup.object({
    passCode: yup.string().required("Transaction Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+_-])[A-Za-z\d@$!%*?&#+_-]{8,}$/,
      "Transaction Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    passCodeConfirm: yup.string().required("Transaction Password confirmation is required").oneOf([yup.ref('passCode')], "Transaction Password do not match"),
});


export const WithdrawSchema = yup.object({
    walletId: yup.string().required("Wallet Address is required"),
    amount: yup.string().required("Amount is required"),
});

export const FundWalletSchema = yup.object().shape({
    image: yup
      .mixed()
      .test("fileType", "Only JPEG, PNG, and JPG files are allowed", (value) => {
        if (!value) return true; // If no file is selected, consider it valid.
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/jpg",
        ];
        const image = value as File;
        return allowedTypes.includes(image.type);
      })
      .required("Please upload a valid file"),
    name: yup.string().required("name is required"),
    mainnet: yup.string().required("mainnet is required"),
    amount: yup.string().required("amount is required"),
  });
  


  export const ResetPasswordSchema = yup.object({
    otp: yup.string().required("One Time Pass Code is required"),
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+_-])[A-Za-z\d@$!%*?&#+_-]{8,}$/,
      "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    confirmPassword: yup.string().required("Password confirmation is required").oneOf([yup.ref('password')], "Password do not match"),
});
  




export const Schema = {
    LoginSchema,
    RegisterSchema,
    BindEmailSchema,
    ChangePasswordSchema,
    AddWalletAddressSchema,
    PassCodeSchema,
    WithdrawSchema,
    FundWalletSchema,
    ResetPasswordSchema
}