import Typography from "antd/es/typography/Typography";
import React from "react";
import QRCode from "react-qr-code";
import { FaGooglePlay } from "react-icons/fa6";

const Download = () => {
  return (
    <div className="home_download">
      <Typography className="download_title">Scan for AIELATE App</Typography>
      <div className="codes">
        <QRCode size={150} value="Download app ulr" />
      </div>

      <div className="Download_Btn">
        <FaGooglePlay size={30} color={`var(--app-primary)`} />
        <span>Download Android App</span>
      </div>

      <p>
        Note: In case of any issues during the download and installation
        process, such as download failures, parsing installation package
        failures, installation failures, etc., please download and install
        again.
      </p>
    </div>
  );
};

export default Download;
