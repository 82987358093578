
import { Terms } from '@/data'
import { Typography } from 'antd'


const User_Terms = () => {
  return (
    <div className='user_terms'>
        <Typography>AIELATE  Business Operation Regulations</Typography>
        {Terms?.map((item, index) => (
            <ul key={index}>
                <li>{item?.list}</li>
            </ul>
        ))}
    </div>
  )
}

export default User_Terms
