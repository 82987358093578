

import { userRequest } from '@/api/connect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface TasksProps {
    totalTasks: string;
    taskDone: string;
}

interface TasksState {
  allTasks: TasksProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: TasksState = {
  allTasks: null,
  loading: false,
  error: null,
};

const allTasksSlice = createSlice({
  name: 'allTasks',
  initialState,
  reducers: {
    fetchAllTasksStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAllTasksSuccess(state, action: PayloadAction<TasksProps>) {
      state.allTasks = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchAllTasksFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAllTasksStart, fetchAllTasksSuccess, fetchAllTasksFailure } = allTasksSlice.actions;

export const fetchAllTasks = () => async (dispatch: any) => {
  try {
    dispatch(fetchAllTasksStart());
    const response = await userRequest.get(`/user/fetch-task`);
    const { data } = response.data
    dispatch(fetchAllTasksSuccess(data));
  } catch (error: any) {
    dispatch(fetchAllTasksFailure(error.message));
  }
};

export default allTasksSlice.reducer;
