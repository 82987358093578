import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { publicRequest, setAuthToken } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserState {
  token: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  token: JSON.parse(localStorage.getItem('user') || 'null'),
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.token = action.payload.data;
      state.isLoading = false;
      state.error = null;
      localStorage.setItem('user', JSON.stringify(state.token));
  
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    logoutUser(state) {
      state.token = null;
      state.isLoading = false;
      state.error = null;
      localStorage.removeItem('user');
      setAuthToken("");
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logoutUser } = userSlice.actions;

export const loginUser = (countryCode: string, phoneNumber: string, password: string) => async (
  dispatch: any
) => {
  try {
    dispatch(loginStart());
    const res = await publicRequest.post(``, { countryCode, phoneNumber, password });
      if(res.data.success === true) {
        setAuthToken(res.data.data.token);
      dispatch(loginSuccess(res.data));
      toast.success(res.data.message);
      } else {
        dispatch(loginFailure(res.data.message))
      }
    } catch (error: any) {
      if(error.code === "ECONNABORTED") {
        dispatch(loginFailure(appStatus.wrong));
      } else if(error.code === "ERR_BAD_REQUEST") {
          dispatch(loginFailure(error?.response?.data?.message[0]))
      } else {
        dispatch(loginFailure(error?.message || error?.response?.message ||  error?.response?.data?.message));
      }
    }
};

export default userSlice.reducer;
