

import { loginUser, logoutUser } from "./auth/Login_Slice";
import { registerUser } from "./auth/Register_Slice";
import { requestEmailBind } from "./dashboard/Request_Email_Bind";
import { bindEmail } from "./dashboard/Bind_Email_Slice"
import { updateChangePassword } from "./dashboard/Change_Password"
import { fetchAllAddress } from "./dashboard/Address_Slice"
import { deleteAddress } from "./dashboard/Delete_Address"
import { fetchUserInfo } from "./dashboard/User_Info.Slice"
import { AddWalletAddress } from "./dashboard/Add_Address_Slice"
import { SetPassCode } from "./dashboard/PassCode_Slice"
import { fetchUserLevel } from "./dashboard/Levels_Slice"
import { fetchUserCrypto } from "./dashboard/Crypto_Slice"
import { UserWithdrawal } from "./dashboard/Withdraw_Slice"
import { fetchAllFundingAddress } from "./dashboard/Fund_Wallet_Address"
import { requestWalletFunding } from "./dashboard/Deposit_Slice"
import { fetchAllTransaction } from "./dashboard/transaction_Slice"
import { fetchAllTasks } from "./dashboard/tasks_Slice"
import { getCurrencyFromIP } from "./currency/Currency"
import { doTask } from "./dashboard/do_task_Slice"
import { requestPasswordOTP } from "./dashboard/request_password_otp"
import { resetPasswordUser } from "./dashboard/reset_password_Slice"
import { EditWalletAddress } from "./dashboard/Edit_Address_Slice"


export const appService = {
    loginUser, 
    logoutUser,
    registerUser,
    requestEmailBind,
    bindEmail,
    updateChangePassword,
    fetchAllAddress,
    deleteAddress,
    fetchUserInfo,
    AddWalletAddress,
    SetPassCode,
    fetchUserLevel,
    fetchUserCrypto,
    UserWithdrawal,
    fetchAllFundingAddress,
    requestWalletFunding,
    fetchAllTransaction,
    fetchAllTasks,
    getCurrencyFromIP,
    doTask,
    requestPasswordOTP,
    resetPasswordUser,
    EditWalletAddress
} 