import React from "react";
import { Skeleton, Typography } from "antd";
import GeneChart from "./Gene_Chart";

interface DataItem {
  category: string;
  values: number[];
}
interface RefStat {
  teamSize?: number;
  newMembers?: number;
  totalLevelOneUser?: number;
  totalLevelOneEarnings?: number;
  totalLevelTwoUser?: number;
  totalLevelTwoEarnings?: number;
  totalLevelThreeUser?: number;
  totalLevelThreeEarnings?: number;
}

interface Wallet {
  todayEarnings?: number;
}

interface GenProps {
  refStat: RefStat;
  loading: boolean;
  wallet: Wallet;
}

const Generation_Data: React.FC<GenProps> = ({ refStat, wallet, loading }) => {
  const data: DataItem[] = [
    { category: "Accumulated Team", values: [refStat?.teamSize ?? 0] },
    { category: "Total Earnings", values: [wallet?.todayEarnings ?? 0] },
    { category: "Valid Member", values: [refStat?.newMembers ?? 0] },
  ];

  return (
    <div className="generation_data_wrapper">
      <div className="gene_chart_data">
        <Typography className="title">Recent Three Generations Data</Typography>
        <GeneChart data={data} loading={loading} />
      </div>

      <div className="effective_user_data">
        <Typography className="title">Effective User Data</Typography>

        <div className="generation_data">
          <Typography>Gen 1 Data</Typography>
          <div className="gen_data_content">
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelOneUser ?? 0
                )}
              </Typography>
              <Typography>Valid Member</Typography>
            </section>
            <section className="gen_data">
              <Typography>20%</Typography>
              <Typography>Commission</Typography>
            </section>
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelOneEarnings ?? 0
                )}
              </Typography>
              <Typography>Total Earnings</Typography>
            </section>
          </div>
        </div>

        <div className="generation_data">
          <Typography>Gen 2 Data</Typography>
          <div className="gen_data_content">
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelTwoUser ?? 0
                )}
              </Typography>
              <Typography>Valid Member</Typography>
            </section>
            <section className="gen_data">
              <Typography>20%</Typography>
              <Typography>Commission</Typography>
            </section>
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelTwoEarnings ?? 0
                )}
              </Typography>
              <Typography>Total Earnings</Typography>
            </section>
          </div>
        </div>

        <div className="generation_data">
          <Typography>Gen 3 Data</Typography>
          <div className="gen_data_content">
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelThreeUser ?? 0
                )}
              </Typography>
              <Typography>Valid Member</Typography>
            </section>
            <section className="gen_data">
              <Typography>20%</Typography>
              <Typography>Commission</Typography>
            </section>
            <section className="gen_data">
              <Typography>
                {loading ? (
                  <Skeleton.Button size="small" />
                ) : (
                  refStat?.totalLevelThreeEarnings ?? 0
                )}
              </Typography>
              <Typography>Total Earnings</Typography>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Generation_Data;
