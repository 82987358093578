import { Skeleton, Typography } from "antd";
import { LockKeyhole } from "lucide-react";
import React, { useEffect, useState } from "react";
import VIPGrades from "./VIP_Grades";
import { upgradeBenefits, upgradeConditions } from "@/data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/config/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";

const VIP_Level: React.FC<TableProps> = ({ data }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch()
  const [selectedLevel, setSelectedLevel] = useState<string | null>("G1");
  const { level, isLoading } = useSelector((state: RootState) => state.level)

  const handleLevelClick = (level: string) => {
    setSelectedLevel(level);
  };

  useEffect(() => {
    dispatch(appService.fetchUserLevel())
  },[dispatch])


  const selectedLevelData = level?.find((lvl: any) => lvl?.levelName === selectedLevel);
  const data1: UpgradeConditionsItem[] = selectedLevelData ? upgradeConditions(selectedLevelData) : [];
  const data2: UpgradeConditionsItem[] = selectedLevelData ? upgradeBenefits(selectedLevelData) : [];


  return (
    <div className="quantify_vip_levels">
      <Typography className="levels">VIP Levels</Typography>

      <div className="vip_level_cards_wrapper">
        {data?.map((item: any) => (
          <div
            className="vip_level_cards"
            style={{ backgroundImage: `url(${item?.img})` }}
            key={item?.id}
            onClick={() => item?.name && handleLevelClick(item?.name)}
          >
            <Typography className="gs">{item?.name}</Typography>
            <Typography className="gs_lock">
              <LockKeyhole color={item?.color} size={20} /> {item?.subName}
            </Typography>
          </div>
        ))}
      </div>

      
      {isLoading ? <Skeleton paragraph={{rows: 6}} /> : (
        <>
          {level && selectedLevel && (
       <>
         <VIPGrades data={data1} loading={isLoading} />
          <VIPGrades data={data2} loading={isLoading} />
       </>
      )}
        </>
      )}

    </div>
  );
};

export default VIP_Level;
