
import React, { useEffect } from "react";
import { Button, InputField } from "../ui";
import { useFormik } from "formik";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { appService } from "@/services";
import { toast } from "react-toastify";
import { RootState } from "@/config/store";
import { Schema } from "@/schema/schema";

interface SuccessProps {
    success: boolean;
    onSuccess: () => void;
    onClose: () => void;
    walletAddress: string;
    mainNet: string;
    addressName: string;
    id: number
}

const Edit_Address:React.FC<SuccessProps> = ({ success, onSuccess, onClose, walletAddress, mainNet, addressName, id }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { error, isLoading } = useSelector((state: RootState) => state.editAddress);

  const formik = useFormik({
    initialValues: {
      walletAddress: walletAddress,
      mainNet: mainNet,
      addressName: addressName,
      id: id
    },
    validationSchema: Schema.AddWalletAddressSchema,
    onSubmit: (values) => {
      dispatch(
        appService.EditWalletAddress(
          values.walletAddress,
          values.mainNet,
          values.addressName,
          values.id
        )
      ).then(() => {
        onSuccess();
      });
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        variant="borderless"
        type="text"
        name="walletAddress"
        label="Wallet Address"
        placeholder="enter wallet address"
        value={formik.values.walletAddress}
        onChange={formik.handleChange}
      />
      <InputField
        variant="borderless"
        type="text"
        name="mainNet"
        label="Main Net"
        placeholder="enter main net"
        value={formik.values.mainNet}
        onChange={formik.handleChange}
      />
      <InputField
        variant="borderless"
        type="text"
        name="addressName"
        label="Address Name"
        placeholder="enter address name"
        value={formik.values.addressName}
        onChange={formik.handleChange}
      />

     <section className="wrapper_cancel">
       <Button
        variant="auth__btn_cancel"
        color="default"
        type="button"
        label="Cancel"
        onClick={onClose}
      />
         <Button
        variant="auth__btn"
        color="primary"
        type="submit"
        label="Update Address"
        loading={isLoading}
        disabled={isLoading}
      />
     </section>
    </form>
  );
};

export default Edit_Address;
