

export const appStatus = {
    userType: ["USER" || "ADMIN" || "DEVELOPER"],
    Pending: "Pending",
    Rejected: "Rejected",
    Approved: "Approved",
    Queried: "Queried",
    All: "All",
    Application: "Applications",
    Group: "Groups",
    wrong: "Something went wrong, please try again!!!",
    netError: "Network Error, please try again!!!",
    timeOut: "timeout of 5000ms exceeded"
}