const Quan_System = () => {
  return (
    <div className="user_terms">
      <ul>
        <li>Time saving and high efficiency</li>
        <li>Zero risk, 100% accurate</li>
        <li>Capable of capturing various market conditions in real time</li>
        <li>24-hour uninterrupted operation and trading</li>
        <li>
          Accurately grasp the exchange rate difference for maximum benefit
        </li>
        <li>Can ensure the maximum profit of each transaction</li>
        <li>Easily realize the stable appreciation of wealth</li>
        <li>Time-consuming and low efficiency</li>
        <li>The accuracy is difficult to grasp and the risk of loss is high</li>
        <li>Unable to grasp multiple market conditions at the same time</li>
        <li>Unable to achieve 24-hour uninterrupted operation and trading</li>
        <li>
          Unable to accurately grasp the exchange rate difference of the maximum
          benefit
        </li>
        <li>
          It is impossible to ensure the maximum profit of each transaction
        </li>
        <li>Unable to ensure the stable appreciation of wealth</li>
      </ul>
    </div>
  );
};

export default Quan_System;
