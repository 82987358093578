import React, { useEffect } from "react";
import { Button, InputField } from "../ui";
import { useFormik } from "formik";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { appService } from "@/services";
import { toast } from "react-toastify";
import { RootState } from "@/config/store";
import { Schema } from "@/schema/schema";
import { HelpCircle } from "lucide-react";

const TransactionPassword = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { error, isLoading } = useSelector((state: RootState) => state.passCode
  );

  const formik = useFormik({
    initialValues: {
      passCode: "",
      passCodeConfirm: "",
    },
    validationSchema: Schema.PassCodeSchema,
    onSubmit: (values) => {
      dispatch(appService.SetPassCode(values.passCode, values.passCodeConfirm));
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <section>
          <InputField
            variant="borderless"
            type="password"
            name="passCode"
            label="Password"
            placeholder="enter transaction password"
            value={formik.values.passCode}
            onChange={formik.handleChange}
          />
          <div className="error_text">
            {formik.touched.passCode &&
              formik.errors.passCode &&
              formik.errors.passCode}
          </div>
        </section>

        <section>
          <InputField
            variant="borderless"
            type="password"
            name="passCodeConfirm"
            label="Confirm Password"
            placeholder="confirm transaction password"
            value={formik.values.passCodeConfirm}
            onChange={formik.handleChange}
          />
          <div className="error_text">
            {formik.touched.passCodeConfirm &&
              formik.errors.passCodeConfirm &&
              formik.errors.passCodeConfirm}
          </div>
        </section>


            <div className="transaction_code">
                <span className="warning"><HelpCircle color={`var(--app-primary)`} /> Warning</span>
                <section>
                    <ol>
                        <li>First setting completes the transaction password setup and allows for withdrawals;</li>
                        <li>Transaction password is only used for withdrawals, and it is recommended not to match the login password;</li>
                        <li>To ensure the safety of funds, withdrawals can only be made 48 hours after the trading password has been changed;</li>
                        <li>Withdrawal password format is only 6 digits from 0 to 9, no symbols or letters can be entered.</li>
                    </ol>
                </section>
            </div>  

                    <Button
          variant="auth__btn"
          color="primary"
          type="submit"
          label="Confirm"
          loading={isLoading}
          disabled={isLoading}
        />  
            </form>
    </div>
  );
};

export default TransactionPassword;
