
import React, { useEffect, useState } from "react";
import { Button, InputField } from "../ui";
import { useFormik } from "formik";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { appService } from "@/services";
import { toast } from "react-toastify";
import { RootState } from "@/config/store";
import { Schema } from "@/schema/schema";
import { Typography } from "antd";

interface SuccessProps {
    success?: boolean;
    onSuccess: () => void;
    onClose: () => void;
}

const Fund_Account:React.FC<SuccessProps> = ({ success, onSuccess, onClose  }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { error, isLoading } = useSelector((state: RootState) => state.requestFunding);
  const { fundAddress } = useSelector((state: RootState) => state.fundAddress)
  const [selectedAddress, setSelectedAddress] = useState<string>("");


  useEffect(() => {
    dispatch(appService.fetchAllFundingAddress())
  },[dispatch])


  const formik = useFormik({
    initialValues: {
      name: "",
      mainnet: selectedAddress,
      amount: "",
      image: null as File | null
    },
    validationSchema: Schema.FundWalletSchema,
    onSubmit: (values) => {
        if (values.image) {
          dispatch(
            appService.requestWalletFunding(
              values.name, 
              values.mainnet,
              values.amount,  
              values.image,
            )
          ).then(() => {
            onSuccess();
          });
          dispatch(appService.fetchAllFundingAddress());
        } else {
          toast.error("Image is required.");
        }
      },
      
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);


  const handleNameChange = (event: any) => {
    const selectedName = event.target.value;
    const selectedAddress = fundAddress?.find((item) => item?.uuid === selectedName)?.mainnet ?? "";
    formik.setFieldValue("name", selectedName);
    formik.setFieldValue("mainnet", selectedAddress);
    setSelectedAddress(selectedAddress);
  };




  return (
    <form onSubmit={formik.handleSubmit}>

       <div className="auth__input">
          <Typography.Text>Wallet Name</Typography.Text>
          <select
            className="input__border select_padd"
            style={{ width: "100%", borderRadius: "10px", padding: "00px 20px" }}
            name="name"
            value={formik.values.name}
            onChange={handleNameChange}
          >
            <option defaultValue="readOnly">Select Wallet Name</option>
            {fundAddress?.map((item) => (
              <option value={item?.uuid} key={item?.uuid}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>

      <InputField
        variant="borderless"
        type="text"
        name="mainnet"
        label="Main Net"
        placeholder="enter main net"
        value={formik.values.mainnet}
        onChange={formik.handleChange}
        disabled={true}
      />
      <InputField
        variant="borderless"
        type="text"
        name="amount"
        label="Amount"
        placeholder="enter amount"
        value={formik.values.amount}
        onChange={formik.handleChange}
      />

        <InputField
            variant="borderless"
            type="file"
            name="image"
            label="Upload Receipt"
            placeholder="enter address name"
            onChange={(event) => formik.setFieldValue("image", event.currentTarget.files?.[0])}
      />

      <section className="wrapper_cancel">
      <Button
        variant="auth__btn_cancel"
        color="primary"
        type="button"
        label="Cancel"
        onClick={onClose}
      />
      <Button
        variant="auth__btn"
        color="primary"
        type="submit"
        label="Confirm"
        loading={isLoading}
        disabled={isLoading}
      />
      </section>
    </form>
  );
};

export default Fund_Account;
