const Quan_License = () => {
  return (
    <div className="user_terms">
      <ul>
        <p>Company name: AIELATE</p>
        <p>Nature of business: Finance</p>
        <p>Registered capital: US$50 million</p>
        <p>
          Registered office: 300 S 4th St, Las Vegas, NV 89101 United States
        </p>
        <p>Country/state of company location: Nevada, USA</p>
        <p>Legal name: Montague Sterling</p>
        <p>Business email: admin@AIELATE.com</p>
        <p className="pt15"></p>
        <p>
          We are proud to announce that AIELATELLC has successfully completed the
          registration process with the U.S. Department of the Treasury’s
          Financial Crimes Enforcement Network (FinCEN) and became an officially
          recognized Money Services Business (MSB)!
        </p>
        <p className="pt15">Nevada Business Identification: NV20243005333</p>
        <p className="pt15">
          This marks an important milestone in our commitment to providing
          transparent and compliant services. We are committed to providing our
          customers with safe and secure financial services, including but not
          limited to: Check Cashing Services Foreign Exchange Transactions Money
          Order Issuance Travelers Checks Our services cover the entire United
          States, from Alabama to Wyoming. As a FinCEN-registered MSB, we are
          committed to continuing to comply with all applicable regulations and
          best practices, ensuring that our customers and partners can trust our
          services.
        </p>
        <p className="pt15">
          Address: 300 S 4th St, Las Vegas, NV 89101, United States 80202 We
          look forward to cooperating with you and opening a new chapter in
          financial services!
        </p>
        <p className="pt15">#Finance #Regulation #Compliance #FinCEN #MSB #AIELATE</p>
      </ul>
    </div>
  );
};

export default Quan_License;
