
import { userRequest } from '@/api/connect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface TransactionProps {
    walletAddress: string;
    reference: string;
    preBalance: number,
    postBalance: number
    amount: number;
    description: string;
    type: string;
    status: string
    color?: string;
}

interface TransactionState {
  transactions: TransactionProps [];
  isLoading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  transactions: [],
  isLoading: false,
  error: null,
};

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    fetchAllTransactionStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchAllTransactionSuccess(state, action: PayloadAction<TransactionProps[]>) {
      state.transactions = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllTransactionFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAllTransactionStart, fetchAllTransactionSuccess, fetchAllTransactionFailure } = transactionSlice.actions;

export const fetchAllTransaction = () => async (dispatch: any) => {
  try {
    dispatch(fetchAllTransactionStart());
    const response = await userRequest.get(`/user/fetch-transactions`);
    const { data } = response.data
    dispatch(fetchAllTransactionSuccess(data));
  } catch (error: any) {
    dispatch(fetchAllTransactionFailure(error.message));
  }
};

export default transactionSlice.reducer;
