import { useDispatch, useSelector } from 'react-redux'
import { TableData } from '../ui'
import { Images } from '@/assets/images'
import { RootState } from '@/config/store'
import { useEffect } from 'react'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { appService } from '@/services'
import { Spin } from 'antd'
import { toast } from 'react-toastify'

type CryptoData = {
  symbol: string,
  price: number,
  percentageChange: string
}

type TransformedData = {
  id: number,
  symbol: string,
  subName: string,
  img: string,
  currentPrice: number,
  percentageChange: number
}

type ImageKeys = keyof typeof Images;

const Binance: React.FC = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch()
  const { crypto, isLoading, error } = useSelector((state: RootState) => state.crypto)

  useEffect(() => {
    dispatch(appService.fetchUserCrypto())
  }, [dispatch])

useEffect(() => {
  if(error) {
    toast.error(error)
  }
},[error])

  const transformData = (cryptoData: CryptoData[]): TransformedData[] => {
    return cryptoData.map((item, index) => {
      const [symbol, subName] = item.symbol.split('/')
      const iconKey = `${symbol}Icon` as ImageKeys
      return {
        id: index + 1,
        symbol,
        subName,
        img: Images[iconKey] || Images.BTCIcon,
        currentPrice: item.price,
        percentageChange: parseFloat(item.percentageChange)
      }
    })
  }

  const staticData: TransformedData[] = [
    {
      id: 1,
      symbol: "BTC",
      subName: "USDT",
      img: Images.BTCIcon,
      currentPrice: 4343,
      percentageChange: -843434,
    },
    {
      id: 2,
      symbol: "BRTC",
      subName: "USDT",
      img: Images.BTCIcon,
      currentPrice: 4343,
      percentageChange: -843434,
    }
  ]

  const data = Array.isArray(crypto) ? transformData(crypto) : staticData

  return (
    <>
      {isLoading ? <Spin fullscreen /> : <TableData data={data} />}
    </>
  )
}

export default Binance
