import { Typography } from 'antd'
import React from 'react'

interface UserProfileProps {
    invitationCode: string
    gender: string
    email: string
    nickName: string
}

interface UsersState {
    profile: UserProfileProps
    phoneNumber: string
    countryCode: string
}
const Personal_Information:React.FC<UsersState> = ({profile, phoneNumber, countryCode}) => {
  return (
    <div>

            <section className='pt-5'>
                <section className='personal_information'>
                    <Typography>Nick Name:</Typography>
                    <Typography>{countryCode + phoneNumber}</Typography>
                </section>

                <section className='personal_information'>
                    <Typography>Invitation Code:</Typography>
                    <Typography>{profile?.invitationCode ?? "0"}</Typography>
                </section>

                <section className='personal_information'>
                    <Typography>Email:</Typography>
                    <Typography>{profile?.email ?? "N/A"}</Typography>
                </section>

                <section className='personal_information'>
                    <Typography>Gender:</Typography>
                    <Typography>{profile?.gender ?? "N/A"}</Typography>
                </section>

                <section className='personal_information'>
                    <Typography>Phone Number:</Typography>
                    <Typography>{countryCode + phoneNumber}</Typography>
                </section>
            </section>
    </div>
  )
}

export default Personal_Information
