import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../services/auth/Login_Slice";
import registerReducer from "../services/auth/Register_Slice"
import requestEmailBindReducer from "../services/dashboard/Request_Email_Bind"
import bindEmailReducer from "../services/dashboard/Bind_Email_Slice"
import changePasswordReducer from "../services/dashboard/Change_Password"
import addressReducer from "../services/dashboard/Address_Slice"
import deleteAddressReducer from "../services/dashboard/Delete_Address"
import infoReducer from "../services/dashboard/User_Info.Slice"
import addAddressReducer from "../services/dashboard/Add_Address_Slice"
import passCodeReducer from "../services/dashboard/PassCode_Slice"
import levelReducer from "../services/dashboard/Levels_Slice"
import cryptoReducer from "../services/dashboard/Crypto_Slice"
import withdrawReducer from "../services/dashboard/Withdraw_Slice"
import fundAddressReducer from "../services/dashboard/Fund_Wallet_Address"
import requestFundingReducer from "../services/dashboard/Deposit_Slice"
import transactionsReducer from "../services/dashboard/transaction_Slice"
import allTasksReducer from "../services/dashboard/tasks_Slice"
import doTaskReducer from "../services/dashboard/do_task_Slice"
import passwordOTPReducer from "../services/dashboard/request_password_otp"
import resetPasswordReducer from "../services/dashboard/reset_password_Slice"
import editAddressReducer from "../services/dashboard/Edit_Address_Slice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        register: registerReducer,
        requestEmailBind: requestEmailBindReducer,
        bindEmail: bindEmailReducer,
        changePassword: changePasswordReducer,
        address: addressReducer,
        deleteAddress: deleteAddressReducer,
        info: infoReducer,
        addAddress: addAddressReducer,
        passCode: passCodeReducer,
        level: levelReducer,
        crypto: cryptoReducer,
        withdraw: withdrawReducer,
        fundAddress: fundAddressReducer,
        requestFunding: requestFundingReducer,
        transactions: transactionsReducer,
        allTasks: allTasksReducer,
        doTask: doTaskReducer,
        passwordOTP: passwordOTPReducer,
        resetPassword: resetPasswordReducer,
        editAddress: editAddressReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
