import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";
import { app } from "@/config/app";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";


interface JwtPayload {
  exp: number;
}

export const Authorized: React.FC<UserAccess> = ({ accessToken, redirect = app.before_auth_path }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();

  useEffect(() => {
    if (accessToken) {
      const checkTokenExpiry = () => {
        try {
          const decodedAccess: JwtPayload = jwtDecode<JwtPayload>(accessToken);
          if (decodedAccess.exp !== undefined) {
            const currentTime = new Date().getTime();
            const tokenExpiryTime = decodedAccess.exp * 1000;
            const expiryTimePlus24Hours = tokenExpiryTime + 24 * 60 * 60 * 1000; // 24 * 60 * 60 * 1000 this is for 24hrs;

            if (currentTime > expiryTimePlus24Hours) {
              dispatch(appService.logoutUser());
            }
          }
        } catch (error: any) {
          console.log("logout error: ", error)
          toast.error("Unauthorized, Please login:", error);
          dispatch(appService.logoutUser());
        }
      };

      // Check token expiry immediately
      checkTokenExpiry();

      const timeoutId = setTimeout(checkTokenExpiry, 1000);

      // Cleanup the timeout on component unmount or when accessToken changes
      return () => clearTimeout(timeoutId);
    }
  }, [accessToken, dispatch]);

  if (!accessToken) {
    return <Navigate to={redirect} replace />;
  }

  return <Outlet />;
};
