import { FaWhatsapp } from 'react-icons/fa6';
import Typography from 'antd/es/typography/Typography';

function WhatsAppMessenger() {

    const handleWhatsAppClick = () => {
        const message = 'Hello, I need assistance!';
        const phoneNumber = '+447435506628';
        
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // For mobile devices, open WhatsApp app
            window.open(`whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
        } else {
            // For desktop, open WhatsApp Web in a new tab
            window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
        }
    };


  return (
    <div
      style={{
        position: 'fixed',
        bottom: '70px',
        right: '20px',
        backgroundColor: 'white',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        display: 'flex', 
        flexDirection: 'column',
        padding: '7px',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
      }}
      onClick={handleWhatsAppClick}
    >
      <FaWhatsapp
        style={{
          fontSize: '30px',
          color: 'green',
          marginBottom: '6px',
          fontWeight: '600',
          transition: 'all 0.3s ease-in-out',
          transform: 'scale(1)',
        }}
      />
      <Typography style={{ 
        textAlign: 'center', 
        fontWeight: 'bold', 
        color: 'green', 
        fontSize: '12px'
        }}>Chat Now</Typography>
    </div>
  );
}

export default WhatsAppMessenger;
