import { Button, CountryCode, InputField } from "@/components";
import { paths } from "@/constants";
import { Checkbox, Typography } from "antd";
import { useFormik } from "formik";
import { ArrowLeft, Globe, Headset } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";
import { Schema } from "@/schema/schema";
import { RootState } from "@/config/store";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<string>("+000");
  const { isLoading, error, register } = useSelector(
    (state: RootState) => state.register
  );

  console.log("registration response:", register?.policy);

  // Function to handle selection of a country code
  const handleSelectCountryCode = (dialCode: string) => {
    setSelectedCountryCode(dialCode);
    formik.setFieldValue("countryCode", dialCode);
  };

 

  const formik = useFormik({
    initialValues: {
      countryCode: selectedCountryCode,
      phoneNumber: "",
      invitationCode: "",
      password: "",
      confirmPassword: "",
      policy: false,
    },
    validationSchema: Schema.RegisterSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(
        appService.registerUser(
          values.countryCode,
          values.phoneNumber,
          values.invitationCode,
          values.password,
          values.confirmPassword,
          values.policy
        )
      );
      formik.resetForm()
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if(register?.policy === false) {
      navigate(paths.login)
    }
  }, [error, register?.policy, navigate ])


  return (
    <div className="wrapper">
      {/* auth top icon */}
      <div className="auth__top">
        <ArrowLeft />
        <section>
          <Globe />
          <Headset />
        </section>
      </div>

      {/* auth welcome message */}

      <div className="auth__welcome">
        <Typography>Hello</Typography>
        <Typography>Welcome to AIELATE</Typography>
      </div>

      {/* auth input field form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="auth__form__wrapper">
          <section>
            <InputField
              variant="borderless"
              size="large"
              type="text"
              placeholder="enter your phone number"
              name="phoneNumber"
              payloadName="countryCode"
              onChange={formik.handleChange}
              countryCodeChange={formik.handleChange}
              value={formik.values.phoneNumber}
              phoneCode={true}
              countryCode={formik.values.countryCode}
              handleOpen={() => setOpen(true)}
              onBlur={formik.handleBlur}
              countryBlur={formik.handleBlur}
            />
            <div className="error_text">
              {formik.touched.phoneNumber &&
                formik.errors.phoneNumber &&
                formik.errors.phoneNumber}
            </div>
          </section>


          <section>
          <InputField
            variant="borderless"
            size="large"
            type="text"
            placeholder="enter invitation code"
            name="invitationCode"
            value={formik.values.invitationCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
           <div className="error_text">
              {formik.touched.invitationCode &&
                formik.errors.invitationCode &&
                formik.errors.invitationCode}
            </div>
          </section>

         
         <section>
         <InputField
            variant="borderless"
            size="large"
            type="password"
            placeholder="enter your password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="error_text">
              {formik.touched.password &&
                formik.errors.password &&
                formik.errors.password}
            </div>
         </section>

          
          <section>
          <InputField
            variant="borderless"
            size="large"
            type="password"
            placeholder="Confirm your password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
           <div className="error_text">
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword &&
                formik.errors.confirmPassword}
            </div>
          </section>

          <div className="auth__remember">
            <Checkbox
              name="policy"
              checked={formik.values.policy}
              onChange={formik.handleChange}
            >
              I accept the privacy policy
            </Checkbox>
            <div className="error_text">
              {formik.touched.policy &&
                formik.errors.policy &&
                formik.errors.policy}
            </div>
          </div>

          <Button
            variant="auth__btn"
            label="Register"
            color="primary"
            type="submit"
            loading={isLoading}
          />

          <div className="auth__no__account">
            <Typography>Already have an account?</Typography>
            <Link
              to={paths.login}
              className="yellow cursor-pointer"
            >
              Login
            </Link>
          </div>

          <Button
            variant="auth__btn__download"
            label="Download App"
            color="primary"
            type="button"
          />
        </div>
      </form>

      <CountryCode
        open={open}
        handleClose={() => setOpen(false)}
        onSelectCountry={handleSelectCountryCode}
      />
    </div>
  );
};

export default Register;
