import React from "react";
import PieChart from "./Charts";
import { Skeleton, Typography } from "antd";
import { ChevronDown } from "lucide-react";

interface EarningsProps {
  data: {
    todayEarnings?: number;
    totalWithdrawal?: number;
  },
  loading: boolean,
  teamSize: string
}

const Earning_Analysis: React.FC<EarningsProps> = ({ data = {}, loading, teamSize }) => {
  const piedata = [
    { labels: "Tasks Earning", series: 17 },
    { labels: "Quantitative Earnings", series: 25 },
    { labels: "Recommended Quantitative Rewards", series: 18 },
  ];

  return (
    <div className="team_earning_analysis">
      <div className="team_summary_top">
        <span>Team Data Overview</span>
        <span>Today <ChevronDown size={14} color={`var(--app-primary)`} /></span>
      </div>

      <PieChart data={piedata} />
      <div className="team_earning_analysis_tb">
        <span>Team Data Overview: <b>0</b></span>
        {/* <span>View Team List</span> */}
      </div>

      <section className='assets_level2'>
        <Typography>
          <span>{loading ? <Skeleton.Button size="small" /> : data.todayEarnings ?? "0"}</span>
          <span>Total Earnings</span>
        </Typography>

        <Typography>
          <span>{loading ? <Skeleton.Button size="small" /> : data.totalWithdrawal ?? "0"}</span>
          <span>Total Withdrawals</span>
        </Typography>

        <Typography>
          <span>{loading ? <Skeleton.Button size="small" /> : teamSize ?? "0"}</span>
          <span>Team Size</span>
        </Typography>
      </section>
    </div>
  );
};

export default Earning_Analysis;
