

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { publicRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserDataProps {
    success: boolean;
    message: string
}

interface PasswordRequestState {
  loading: boolean;
  err: string | null;
  passwordOTP: UserDataProps | null;
}

const initialState: PasswordRequestState = {
  loading: false,
  err: null,
  passwordOTP: null,
};

const passwordOTPSlice = createSlice({
  name: 'passwordOTP',
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.err = null;
    },
    requestSuccess(state, action: PayloadAction<UserDataProps>) {
      state.loading = false;
      state.err = null;
      state.passwordOTP = action.payload;
    },
    requestFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.err = action.payload;
    },
  },
});

export const { requestStart, requestSuccess, requestFailure } = passwordOTPSlice.actions;

export const requestPasswordOTP = (email: string) => async (dispatch: any) => {
  try {
    dispatch(requestStart());
    const response = await publicRequest.post(`/request-reset-password`, {email});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(requestSuccess(data));
      toast.success(message);
    } 
  } catch (err: any) {
    if(err.code === 'ECONNABORTED') {
      dispatch(requestFailure(appStatus.wrong));
    } else {
      dispatch(requestFailure(err?.message));
    }
  }
};

export default passwordOTPSlice.reducer;
