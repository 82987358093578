import { AssetOverview, BindEmail, DashboardLayout, InviteFriends, LoginPassword, PersonalInformation, ProfileModal, TransactionPassword, UserTerms, WalletAddress } from '@/components'
import { User, ChevronRight, CrownIcon, LogOutIcon, Copy, Mail, LockKeyhole, CircleDollarSign, NotebookTabs, MessageSquareText, FileQuestion, FileText, Globe, Paintbrush, Smartphone } from 'lucide-react'
import Typography from 'antd/es/typography/Typography'
import { Images } from '@/assets/images'
import { Link, useNavigate } from 'react-router-dom'
import { paths } from '@/constants'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/config/store'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { appService } from '@/services'
import { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { handleCopyClick } from '@/utils/Utils'

const Profile = () => {
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<any>(null);
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const { info, isLoading } = useSelector((state: RootState) => state.info);
    const [ viewDetail, setViewDetail] = useState<boolean>(false);
    const [ isHeight, setIsHeight ] = useState<string | number>("");
    const [ isInviteFriends, setIsInviteFriends ] = useState<boolean>(false);

    useEffect(() => {
        dispatch(appService.fetchUserInfo())
    },[dispatch])



    // a function to open each profile details modal
    const handleProfileTabClick = (content: any, label: string, height: string | number) => {
        setModalContent(content);
        setModalTitle(label);
        setIsModalVisible(true);
        setIsHeight(height)
    };

    const handleInviteFriends = () => {
        setIsInviteFriends(!isInviteFriends)
    }

    const profileTabs = [
        {
            id: 1,
            icon: <Mail size={15}/>,
            label: "Bind Email",
            content: <BindEmail />,
            height: 378,
            path: null
        },
    
        {
            id: 2,
            icon: <LockKeyhole size={15}/>,
            label: "Change Login Password",
            content: <LoginPassword />,
            height: 378,
            path: null
        },
    
        {
            id: 3,
            icon: <CircleDollarSign size={15} />,
            label: "Transaction Password",
            content: <TransactionPassword />,
            height: 378,
            path: null
        },
    
        {
            id: 4,
            icon: <NotebookTabs size={15}/>,
            label: "Binding Address",
            content: <WalletAddress />,
            height: 378,
            path: null
        },
    
        {
            id: 5,
            icon: <MessageSquareText size={15}/>,
            label: "Feedback",
            content: null,
            height: 378,
            path: null
        },
    
        {
            id: 6,
            icon: <FileQuestion size={15}/>,
            label: "Help Center",
            content: null,
            height: 378,
            path: null
        },
    
        {
            id: 7,
            icon: <FileText size={15}/>,
            label: "User Terms",
            content: <UserTerms />,
            height: '600px',
            path: null
        },
        
        {
            id: 8,
            icon: <Globe size={15}/>,
            label: "Switch Language",
            content: null,
            height: 200,
            path: null
        },
    
        {
            id: 9,
            icon: <Paintbrush size={15}/>,
            label: "Clear Cache",
            content: null,
            height: 378,
            path: null
        },
    
        {
            id: 10,
            icon: <Smartphone size={15}/>,
            label: "Download App",
            content: null,
            height: 378,
            path: null
        },
    ]

  return (
    <DashboardLayout logo={true} title="AIELATE Profile" >
        <div className='invitation_code_wrapper'>
                <section className='invitation_code'>
                    <span className='user_img'><img src={Images.WealthContentImg} alt=""/> </span>
                   
                            <Typography>
                            <span>{isLoading ? <Skeleton.Button active={true}  size="small"/> : info?.profile?.nickName ?? "N/A"}</span>
                            <span>Invitation Code: <b>{isLoading ? <Skeleton.Button active={true}  size="small"/> : info?.profile?.invitationCode ?? "0"}</b> <Copy color={`var(--app-yellow)`} onClick={() => handleCopyClick(info?.profile?.invitationCode)}/></span>
                        </Typography>
                </section>
                <ChevronRight size={20} onClick={() => setViewDetail(true)}/>
        </div>
    
        {/* invite friends */}
        <div className='invite_friend_wrapper'>
            <section className='invite_friends' onClick={() => handleInviteFriends()}>
                <section>
                <User color={`var(--app-primary)`} size={20}/>
                <Typography>Invite Friends</Typography>
                </section>
                <ChevronRight color={`var(--app-primary)`} size={20} />
            </section>

            {/* upgrade */}
            <div className='friends_upgrade'>
                <section>
                    <CrownIcon/>
                    <Typography>Currently open the highest discount channel</Typography>
                </section>

                <Typography className='upgrade_btn'>Upgrade</Typography>

            </div> 
        </div>

            {/* asset overview component */}
            <AssetOverview data={info?.wallet} size={info?.refStat} loading={isLoading} />

            {/* wealth content */}
            <div className='wealth_content_wrapper'>
                <section className='wealth_content'>
                    <Typography>Wealth Content</Typography>
                    <Typography>Participate in the event and get rewards</Typography>
                </section>
                <img src={Images.WealthContentImg} alt="wealth content"/>
            </div>

            {/* profile tabs */}
            <div className='profile_tabs_wrapper'>
                {profileTabs?.map((item) => (
                    <section onClick={() => {
                        if (item?.path !== null) {
                          navigate(item?.path);
                        } else if (item?.content !== null) {
                        handleProfileTabClick(item.content, item.label, item.height);
                        }
                      }} >
                        <Typography>
                            {item?.icon}
                            {item?.label}
                        </Typography>

                        <ChevronRight size={20} />
                    </section>
                ))}
            </div>

            {/* user logout */}
            <div className='profile_tabs_wrapper'>
                <Link to={paths.login} onClick={() => dispatch(appService.logoutUser())}>
                    <Typography className='logout'><LogOutIcon color={`var(--app-warning)`} /> Logout</Typography>
                </Link>
            </div>

           {modalContent !== null && (
             <ProfileModal 
             open={isModalVisible}
             handleClose={() => setIsModalVisible(false)}
             title={modalTitle}
             newHeight={isHeight}
         >
             {modalContent}
         </ProfileModal>
           )}

           {viewDetail && (
            <ProfileModal open={viewDetail} handleClose={() => setViewDetail(false)} title='Personal Information'>
                <PersonalInformation profile={info?.profile} countryCode={info?.countryCode} phoneNumber={info?.phoneNumber} />
            </ProfileModal>
           )}

           {isInviteFriends && (
            <ProfileModal
                handleClose={() => handleInviteFriends()}
                open={isInviteFriends}
                newHeight={650}
                title='Invite Friend'
            >
                <InviteFriends />
            </ProfileModal>
           )}
    </DashboardLayout>
  )
}

export default Profile
