import { Button, InputField } from "@/components";
import { paths } from "@/constants";
import {  Typography } from "antd";
import { ArrowLeft, Globe, Headset } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";
import { Schema } from "@/schema/schema";
import { RootState } from "@/config/store";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

const Change_Password = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { loading, err } = useSelector((state: RootState) => state.passwordOTP);
  const { isLoading, error } = useSelector((state: RootState) => state.resetPassword)


useEffect(() => {
    if (err) {
        toast.error(err);
      }

      if (error) {
        toast.error(error);
      }
},[err, error])


  const formik = useFormik({
    initialValues: {
    otp: "",
    email: "",
    password: "",
    confirmPassword: "",
    },
    validationSchema: Schema.ResetPasswordSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(
        appService.resetPasswordUser(
            values.otp,
            values.email,
            values.password,
            values.confirmPassword,
        )
      );
    },
  });

  console.log("reset password:", formik.values)

  const userEmail = formik.values.email

  const handleSendActivationCode = async () => {
    dispatch(appService.requestPasswordOTP(userEmail))
  };


  return (
    <div className="wrapper">
      {/* auth top icon */}
      <div className="auth__top">
        <ArrowLeft />
        <section>
          <Globe />
          <Headset />
        </section>
      </div>

      {/* auth welcome message */}

      <div className="auth__welcome">
        <Typography>Hello</Typography>
        <Typography>Reset Login Password</Typography>
      </div>

      {/* auth input field form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="auth__form__wrapper">

        <section>
          <InputField
            variant="borderless"
            size="large"
            type="email"
            placeholder="enter email address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            suffix={<span className='auth_suffix' onClick={() => handleSendActivationCode()}>{loading ? "sending..." : "Get Code"}</span>}
          />
           <div className="error_text">
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </div>
          </section>
        
          <section>
          <InputField
            variant="borderless"
            size="large"
            length={6}
            value={formik.values.otp}
            name="otp"
            onChange={(value) => formik.setFieldValue("otp", value)}
            onBlur={formik.handleBlur}
          />
           <div className="error_text">
              {formik.touched.otp &&
                formik.errors.otp &&
                formik.errors.otp}
            </div>
          </section>

         
         <section>
         <InputField
            variant="borderless"
            size="large"
            type="password"
            placeholder="enter your password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="error_text">
              {formik.touched.password &&
                formik.errors.password &&
                formik.errors.password}
            </div>
         </section>

          
          <section>
          <InputField
            variant="borderless"
            size="large"
            type="password"
            placeholder="Confirm your password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
           <div className="error_text">
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword &&
                formik.errors.confirmPassword}
            </div>
          </section>


          <Button
            variant="auth__btn"
            label="Confirm"
            color="primary"
            type="submit"
            loading={isLoading}
          />

          <div className="auth__no__account">
            <Typography>Proceed to?</Typography>
            <Link
              to={paths.login}
              className="yellow cursor-pointer"
            >
              Login
            </Link>
          </div>

        </div>
      </form>

    </div>
  );
};

export default Change_Password;
