import React, { useEffect } from "react";
import { Button, InputField } from "../ui";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/config/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { appService } from "@/services";
import { useFormik } from "formik";
import { Schema } from "@/schema/schema";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { paths } from "@/constants";

const Withdraw = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { address, isLoading } = useSelector(
    (state: RootState) => state.address
  );
  const { loading, error } = useSelector((state: RootState) => state.withdraw);

  useEffect(() => {
    dispatch(appService.fetchAllAddress());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      walletId: "",
      amount: "",
    },
    validationSchema: Schema.WithdrawSchema,
    onSubmit: (values) => {
      dispatch(appService.UserWithdrawal(values.walletId, values.amount));
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Typography className="deposit_fund_desc">
          Please, before you can withdraw, you have to add your wallet address
          first by clicking on, <Link to={paths.profile}>Profile</Link> and click on Binding Address but if already added, you can proceed
        </Typography>
        <div className="auth__input">
          <Typography.Text>Wallet Name</Typography.Text>
          <select
            className="input__border select_padd"
            style={{
              width: "100%",
              borderRadius: "10px",
              padding: "00px 20px",
            }}
            disabled={isLoading}
            value={formik.values.walletId}
            name="walletId"
            onChange={formik.handleChange}
          >
            <option defaultValue="readOnly">Select Wallet Name</option>
            {address?.map((item) => (
              <option value={item?.uuid} key={item?.uuid}>
                {item?.addressName}
              </option>
            ))}
          </select>
        </div>

        <InputField
          type="text"
          placeholder="enter amount to withdraw"
          variant="borderless"
          name="amount"
          label="Amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
        />

        <section style={{ marginTop: "2rem" }}>
          <Button
            type="submit"
            variant="auth__btn"
            color="primary"
            label="Confirm"
            loading={loading}
          />
        </section>
      </form>
    </div>
  );
};

export default Withdraw;
