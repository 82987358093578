import { Images } from "@/assets/images";


export const viplevels = [
    {
        id: 1,
        img: Images.G1,
        name: "G1",
        subName: "Not unlocked this level yet",
        color: `var(--app-g1)`
    },

    {
        id: 2,
        img: Images.G2,
        name: "G2",
        subName: "Not unlocked this level yet",
        color: `var(--app-g2)`
    },

    {
        id: 3,
        img: Images.G3,
        name: "G3",
        subName: "Not unlocked this level yet",
        color: `var(--app-g3)`
    },

    {
        id: 4,
        img: Images.G4,
        name: "G4",
        subName: "Not unlocked this level yet",
        color: `var(--app-g4)`
    },

    {
        id: 5,
        img: Images.G5,
        name: "G5",
        subName: "Not unlocked this level yet",
        color: `var(--app-g5)`
    },

    {
        id: 6,
        img: Images.G6,
        name: "G6",
        subName: "Not unlocked this level yet",
        color: `var(--app-white)`
    },
]




export const upgradeConditions = (level: Level): UpgradeConditionsItem[] => [
    {
        caption: `VIP ${level?.levelName}: Upgrade Conditions`,
    },

    {
      id: 1,
      name: "Effective Amount",
      subName: "3/35"
    },
    {
      id: 2,
      name: "1st Generation Valid Members",
      subName: level?.firstGenerationMember
    },
    {
      id: 3,
      name: "2nd Generation Valid Members",
      subName: level?.secondGenerationMember
    },
    {
      id: 4,
      name: "3rd Generation Valid Members",
      subName: level?.thirdGenerationMember
    },
  ];

export const upgradeBenefits = (level: Level): UpgradeConditionsItem[] => [

    {
        caption: `VIP ${level?.levelName}: Benefits`,
    },

    {
        id: 1,
        name: "Minimum Amount Quantification",
        subName: level?.minAmountQualified
    },

    {
        id: 2,
        name: "Maximum Amount Quantification",
        subName: level?.maxAmountQualified
    },

    {
        id: 3,
        name: "Daily Quantified Times",
        subName: level?.dialyQuantifiedTimes
    },

    {
        id: 4,
        name: "Return on Investment",
        subName: level?.returnInvestment
    },
]