



import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStatus } from '../../constants';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appService } from '..';


interface UserDataProps {
    success: boolean;
    message: string
}

interface RegisterState {
  loading: boolean;
  err: string | null;
  deleteAddress: UserDataProps | null;
}

const initialState: RegisterState = {
  loading: false,
  err: null,
  deleteAddress: null,
};

const deleteAddressSlice = createSlice({
  name: 'deleteAddress',
  initialState,
  reducers: {
    deleteAddressStart(state) {
      state.loading = true;
      state.err = null;
    },
    deleteAddressSuccess(state, action: PayloadAction<UserDataProps>) {
      state.loading = false;
      state.err = null;
      state.deleteAddress = action.payload;
    },
    deleteAddressFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.err = action.payload;
    },
  },
});

export const { deleteAddressStart, deleteAddressSuccess, deleteAddressFailure } = deleteAddressSlice.actions;

export const deleteAddress = (id: number) => async (dispatch: any) => {
  try {
    dispatch(deleteAddressStart());
    const response = await userRequest.delete(`/wallet/wallet-address`, { data: { id } });
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(deleteAddressSuccess(data));
      toast.success(message);
      dispatch(appService.fetchAllAddress());
    } 
  } catch (err: any) {
    let errorMessage = '';
   
    if (err.response) {
      if (err.response.data.statusCode === 400) {
        errorMessage = err.response.data.message[0];
      } else if (err.response.status === 0 && err.response.statusText === "error") {
        errorMessage = appStatus.wrong;
      } else if (err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      } else {
        errorMessage = err.message;
      }
    } else {
      errorMessage = err.message;
    }
    dispatch(deleteAddressFailure(errorMessage));
  }
};

export default deleteAddressSlice.reducer;
