
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


export const handleCopyClick = (copyId: string) => {
    navigator.clipboard.writeText(copyId);
    toast.success("Copied Successfully");
  };


  // export const formatAmount = (amount: number | string) => {
  //   amount = Number(amount);
  //   if (isNaN(amount)) {
  //     return 'Invalid Amount';
  //   }
  
  //   const formatter = new Intl.NumberFormat('en-US', {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  
  //   return formatter.format(amount);
  // };


  export const formatAmount = (amount: number | string): string => {
    if (amount !== undefined && amount !== null) {
      // Converting the amount to a string and remove commas
      amount = amount.toString().replace(/,/g, '');
  
      // Convert the cleaned amount to a number and format it with two decimal places and commas for thousands
      const formattedAmount = Number(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
      return `${formattedAmount}`;
    }
    return '';
  };



  export const useCurrentTime = (timeString: string) => {
    const [currentTime, setCurrentTime] = useState(new Date(timeString));
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  
    const formattedDate = currentTime.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    return { formattedDate, formattedTime };
  };
  