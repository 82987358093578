import React from 'react'

const ChatBot = () => {
  return (
    <div>
        <p>Please, make used of the automated chat system</p>
    </div>
  )
}

export default ChatBot
