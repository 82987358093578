
import { userRequest } from '@/api/connect';
import { appStatus } from '@/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface WalletAddress {
  walletAddress: string;
  mainNet: string;
  uuid: string;
  addressName: string;
  id: number
}

interface AddressState {
  address: WalletAddress[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AddressState = {
  address: [],
  isLoading: false,
  error: null,
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    fetchAllAddressStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchAllAddressSuccess(state, action: PayloadAction<WalletAddress[]>) {
      state.address = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllAddressFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAllAddressStart, fetchAllAddressSuccess, fetchAllAddressFailure } = addressSlice.actions;

export const fetchAllAddress = () => async (dispatch: any) => {
  try {
    dispatch(fetchAllAddressStart());
    const response = await userRequest.get(`/wallet/wallet-address`);
    const { data } = response.data
    dispatch(fetchAllAddressSuccess(data));
  } catch (error: any) {
    let errorMessage = appStatus.wrong; 
   if(error.response) {
    if (error.response.data.statusCode === 500) {
        errorMessage = appStatus.wrong;
      } else {
          errorMessage = error.message || error.response.message || error.response.data.message
      }
   } else {
    errorMessage = error.message || error.response.message || error.response.data.message
   }
    dispatch(fetchAllAddressFailure(errorMessage));
  }
};

export default addressSlice.reducer;
