
import { Button, CountryCode, InputField } from "@/components";
import { RootState } from "@/config/store";
import { paths } from "@/constants";
import { Schema } from "@/schema/schema";
import { appService } from "@/services";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Checkbox, Typography } from "antd";
import { useFormik } from "formik";
import { ArrowLeft, Globe, Headset } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate()
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [ open, setOpen ] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("+000");
  const { token, isLoading, error } = useSelector((state: RootState) => state.auth); 

  // Function to handle selection of a country code
  const handleSelectCountryCode = (dialCode: string) => {
    setSelectedCountryCode(dialCode);
    formik.setFieldValue('countryCode', dialCode);
  };



  const formik = useFormik({
    initialValues: {
        countryCode: selectedCountryCode,
        phoneNumber: "",
        password: ""
    },
    validationSchema: Schema.LoginSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(appService.loginUser(values.countryCode, values.phoneNumber, values.password));
    }
  });
  

    useEffect(() => {
    if (token) {
      navigate(paths.home);
    }
  }, [navigate, token]);

  
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div className="wrapper">
      {/* auth top icon */}
      <div className="auth__top">
        <ArrowLeft />
        <section>
          <Globe />
          <Headset />
        </section>
      </div>

      {/* auth welcome message */}

      <div className="auth__welcome">
        <Typography>Hello</Typography>
        <Typography>Welcome to AIELATE</Typography>
      </div>

      {/* auth input field form */}
      <form onSubmit={formik.handleSubmit}>
      <div className="auth__form__wrapper">
       <section>
       <InputField
          variant="borderless"
          size="large"
          type="text"
          placeholder="enter your phone number"
          name="phoneNumber"
          payloadName="countryCode"
          onChange={formik.handleChange}
          countryCodeChange={formik.handleChange}
          value={formik.values.phoneNumber}
          phoneCode={true}
          countryCode={formik.values.countryCode}
          handleOpen={() => setOpen(true)}  
        />
        <div className="error_text">
        {formik.touched.phoneNumber &&
            formik.errors.phoneNumber &&
            formik.errors.phoneNumber}
        </div>
       </section>

       <section>
       <InputField
          variant="borderless"
          size="large"
          type="password"
          placeholder="enter your password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
         <div className="error_text">
        {formik.touched.password &&
            formik.errors.password &&
            formik.errors.password}
        </div>
       </section>

        <div className="auth__remember">
            <Checkbox >Remember Password</Checkbox>
            <Link to={paths.changePassword} className="yellow cursor-pointer">Forgot Password</Link>
        </div>

        <Button variant="auth__btn" label="Login" color="primary" type="submit" loading={isLoading} disabled={isLoading} />

        <div className="auth__no__account">
            <Typography>Don't have an account yet?</Typography>
            <Link to={paths.register} className="yellow cursor-pointer">Sign Up</Link>
        </div>

        <Button variant="auth__btn__download" label="Download App" color="primary" type="button" />

        <div className="auth__support">
          {/* <img src={Images.Logo} alt="customer support" width={100} height={80} /> */}
        </div>
      </div>
      </form>

      <CountryCode open={open} handleClose={() => setOpen(false)} onSelectCountry={handleSelectCountryCode} />
    </div>
  );
};

export default Login;
