
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from './Routes'
import { ToastContainer } from 'react-toastify'


const App = () => {
  return (
   <>
    <Router>
      <AppRoutes />
    </Router>
    <ToastContainer  position='top-center'/>
   </>
  )
}

export default App
