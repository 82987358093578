







import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserDataProps {
    success: boolean;
    message: string
}

interface AddAddressState {
  isLoading: boolean;
  error: string | null;
  passCode: UserDataProps | null;
}

const initialState: AddAddressState = {
  isLoading: false,
  error: null,
  passCode: null,
};

const passCodeSlice = createSlice({
  name: 'passCode',
  initialState,
  reducers: {
    setPassCodeStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    setPassCodeSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.passCode = action.payload;
    },
    setPassCodeFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setPassCodeStart, setPassCodeSuccess, setPassCodeFailure } = passCodeSlice.actions;

export const SetPassCode = (passCode: string, passCodeConfirm: string,) => async (dispatch: any) => {
  try {
    dispatch(setPassCodeStart());
    const response = await userRequest.put(`/user/set-pass-code`, {passCode, passCodeConfirm});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(setPassCodeSuccess(data));
      toast.success(message);
    }
  } catch (error: any) {
    let errorMessage = '';
    if (error.response) {
      if (error.response.data.statusCode === 400) {
        errorMessage = error.response.data.message[0];
      } else if (error.response.statusCode === 0 && error.response.statusText === "error") {
        errorMessage = appStatus.wrong;
      } else {
        errorMessage = error.message || error.response.data.message;
      }
    } else {
      errorMessage = error.message;
    }
    dispatch(setPassCodeFailure(errorMessage))
  }
};

export default passCodeSlice.reducer;
