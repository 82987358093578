
import React, { useEffect } from 'react'
import { Button, InputField } from '../ui'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { appService } from '@/services'
import { Schema } from '@/schema/schema'
import { RootState } from '@/config/store'
import { toast } from 'react-toastify'

const Login_Password = () => {
    const dispatch: ThunkDispatch<any, any, any> = useDispatch()
    const { isLoading, error } = useSelector((state: RootState) => state.changePassword)
    
  

    useEffect(() => {
        if(error) {
            toast.error(error)
        }
    }, [error])


    const formik = useFormik({
        initialValues: {
            newPassword: "",
            oldPassword: "",
        },
        validationSchema: Schema.ChangePasswordSchema,
        onSubmit: (values) => {
            dispatch(appService.updateChangePassword(values.newPassword, values.oldPassword))
        }
    })

   

  return (
    <div>
        <form onSubmit={formik.handleSubmit}>

        <section className='w-full profile_modal_padding'>
        <InputField 
            variant="borderless"
            size="large"
            type="password"
            placeholder="Enter your email address"
            name="newPassword"
            label='New Password'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
          />

        <InputField 
            variant="borderless"
            size="large"
            type="password"
            placeholder="Enter verification code"
            name="oldPassword"
            label='Old Password'
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            
          />
        </section>  
        
        <Button variant="auth__btn" label="Confirm" color="primary" type="submit" loading={isLoading } />  
        </form>
    </div>
  )
}

export default Login_Password
