

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStatus } from '../../constants';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';


interface UserDataProps {
    success: boolean;
    message: string
}

interface RegisterState {
  loading: boolean;
  err: string | null;
  requestEmailBind: UserDataProps | null;
}

const initialState: RegisterState = {
  loading: false,
  err: null,
  requestEmailBind: null,
};

const registerSlice = createSlice({
  name: 'requestEmailBind',
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.err = null;
    },
    requestSuccess(state, action: PayloadAction<UserDataProps>) {
      state.loading = false;
      state.err = null;
      state.requestEmailBind = action.payload;
    },
    requestFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.err = action.payload;
    },
  },
});

export const { requestStart, requestSuccess, requestFailure } = registerSlice.actions;

export const requestEmailBind = (email: string) => async (dispatch: any) => {
  try {
    dispatch(requestStart());
    const response = await userRequest.post(`/user/request-email-bind`, {email});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(requestSuccess(data));
      toast.success(message);
    } 
  } catch (err: any) {
    let errorMessage = '';
   
    if (err.response) {
      if (err.response.data.statusCode === 400) {
        errorMessage = err.response.data.message[0];
      } else if (err.response.status === 0 && err.response.statusText === "error") {
        errorMessage = appStatus.wrong;
      } else if (err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      } else {
        errorMessage = err.message;
      }
    } else {
      errorMessage = err.message;
    }
    dispatch(requestFailure(errorMessage));
  }
};

export default registerSlice.reducer;
