

import React, { useEffect, useState } from 'react';

interface FadeImageProps {
  images: string[];
  interval?: number;
}

const FadeImage: React.FC<FadeImageProps> = ({ images, interval = 3000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images, interval]);

  return (
    <div className="home_video">
      <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
    </div>
  );
};

export default FadeImage;
