import { paths } from "@/constants";




export const app = {
    apiBaseUrl: process.env.REACT_APP_API,
    before_auth_path: paths.login,
    after_auth_path: paths.home,
    company: {
        name: 'QUANTIFY',
        url: 'quantify.com',
        full_url: 'https://quantify.com',
        // headerKey: import.meta.env.VITE_APP_SECRET_URL
    }
}