import React from "react";

const Quan_Trading = () => {
  return (
    <div className='user_terms'>
      <ul>
        <li>
          Quantitative trading is based on the trading and investment mode that
          applies logic of modern statistics and mathematics, to issue trading
          orders through quantitative methods and computer programs to obtain
          stable returns. Sifting and formulating precise trading strategies
          from a large historical database so that each fund can easily reach
          the expected return index, and achieve continuous, stable and
          above-average returns.
        </li>
        <li>
          Quantitative trading originated in the 1970s, and has been rapidly
          developed and popularized since then. Quantitative trading has been
          recognized by an increasing numbers investors. It has now gradually
          become the mainstream in the investment field. It has stable
          investment performance and continuous expansion of market size and
          share.
        </li>
        <li>
          Many disadvantages of manual trading have result in obstacles to
          profit; whilst the accuracy and 100% execution rate of quantitative
          trading have brought obvious advantages to the profit of quantitative
          trading.
        </li>
        <li>
          With the advent of the era of artificial intelligence and the rapid
          development of the Internet, the new concept of quantitative trading
          has become common practice around the world; and with new
          breakthroughs in the field of intelligence technology, this also plays
          a significant role in promoting the development of quantitative
          trading.
        </li>
      </ul>
    </div>
  );
};

export default Quan_Trading;
