import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart: React.FC<PieChartItems> = ({ data }) => {

  const labels = data.map(item => item.labels);
  const series = data.map(item => item.series);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'pie',
      height: 300
    },
    labels: labels,
    series: series,
    legend: {
      show: true,
      position: 'right',
      labels: {
        colors: 'black',
      },
    },
    dataLabels: {
        enabled: false
      },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
        }
      }
    }]
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height={200}
      tooltip={false}
    />
  );
};

export default PieChart;
