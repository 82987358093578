





import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserDataProps {
    success: boolean;
    message: string
}

interface RegisterState {
  isLoading: boolean;
  error: string | null;
  changePassword: UserDataProps | null;
}

const initialState: RegisterState = {
  isLoading: false,
  error: null,
  changePassword: null,
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    changeStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    changeSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.changePassword = action.payload;
    },
    changeFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { changeStart, changeSuccess, changeFailure } = changePasswordSlice.actions;

export const updateChangePassword = (newPassword: string, oldPassword: string) => async (dispatch: any) => {
  try {
    dispatch(changeStart());
    const response = await userRequest.patch(`/change-password`, {newPassword, oldPassword});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(changeSuccess(data));
      toast.success(message);
    }
  } catch (error: any) {
    let errorMessage = '';
    if (error.response) {
        if (error.response.data.statusCode === 400) {
          errorMessage = error.response.data.message[0];
        } else if (error.response.status === 0 && error.response.statusText === "error") {
          errorMessage = appStatus.wrong;
        } else if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message;
      }
    dispatch(changeFailure(errorMessage))
  }
};

export default changePasswordSlice.reducer;
