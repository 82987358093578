
import { app } from '@/config/app';
import axios from 'axios';


export const publicRequest = axios.create({
  baseURL: app.apiBaseUrl,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const userRequest = axios.create({
  baseURL: app.apiBaseUrl,
});

export const setAuthToken = (TOKEN: any) => {
  userRequest.defaults.headers.Authorization = `Bearer ${TOKEN}`;
};

const TOKEN: string | null = JSON.parse(localStorage.getItem("user") || "null");
if (TOKEN) {
  setAuthToken(TOKEN);
}

