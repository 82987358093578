import { FileSearch } from "lucide-react";
import React from "react";

interface ErrorProps {
  text?: string;
}

const Empty:React.FC<ErrorProps> = ({ text }) => {
  return (
    <div className="no-results">
      <FileSearch size={100} />
      <span>{text}</span>
    </div>
  );
};

export default Empty;
