


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserDataProps {
    success: boolean;
    message: string
}

interface AddAddressState {
  loading: boolean;
  error: string | null;
  withdraw: UserDataProps | null;
}

const initialState: AddAddressState = {
  loading: false,
  error: null,
  withdraw: null,
};

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    withdrawStart(state) {
      state.loading = true;
      state.error = null;
    },
    withdrawSuccess(state, action: PayloadAction<UserDataProps>) {
      state.loading = false;
      state.error = null;
      state.withdraw = action.payload;
    },
    withdrawFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { withdrawStart, withdrawSuccess, withdrawFailure } = withdrawSlice.actions;

export const UserWithdrawal = (walletId: string, amount: string) => async (dispatch: any) => {
  try {
    dispatch(withdrawStart());
    const response = await userRequest.post(`/user/withdraw`, {walletId, amount});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(withdrawSuccess(data));
      toast.success(message);
    } else {
      dispatch(withdrawFailure(message))
    }
  } catch (error: any) {
    let errorMessage = '';
    if (error.response) {
      if (error.response.data.statusCode === 400) {
        errorMessage = error.response.data.message[0];
      } else if (error.response.statusCode === 0 && error.response.statusText === "error") {
        errorMessage = appStatus.wrong;
      } else {
        errorMessage = error.message || error.response.data.message;
      }
    } else {
      errorMessage = error.response.message;
    }
    dispatch(withdrawFailure(errorMessage))
  }
};

export default withdrawSlice.reducer;
