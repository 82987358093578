
import { DashboardLayout, EarningAnalysis, GenerationData } from '@/components'
import { RootState } from '@/config/store'
import { appService } from '@/services'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { Skeleton, Typography } from 'antd'
import { ChevronDown } from 'lucide-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Team = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { info, isLoading } = useSelector((state: RootState) => state.info);

  useEffect(() => {
      dispatch(appService.fetchUserInfo())
  },[dispatch])


  return (
    <DashboardLayout title='Team'>
        {/* team heading */}
        <div className='team_summary_wrapper'>
            <section className='team_summary_inner'>
                  <div className='team_summary_top'>
                    <span>Team Data Overview</span>
                    <span>Today <ChevronDown size={14} color={`var(--app-primary)`} /></span>
                  </div>

                  <div className='team_summary_bottom'>
                      <section>
                          <span>{isLoading ? <Skeleton.Button /> : info?.refStat?.newMembers ?? "0"}</span>
                          <span>New Members</span>
                      </section>
                      <section>
                          <span>{isLoading ? <Skeleton.Button /> :info?.level?.levelName ??  "0"}</span>
                          <span>Level</span>
                      </section>
                      <section>
                          <span>{isLoading ? <Skeleton.Button /> : info?.refStat?.newEarnings ?? "0"}</span>
                          <span>New Earnings</span>
                      </section>
                  </div>
            </section>

            <div className="booster_accelerator">
            <Typography>Team Accelerator Pack</Typography>
            <section>
              {/* <Typography>Select Accelerator Pack</Typography>
              <ChevronRight size={15} color={`var(--app-gray)`} /> */}
            </section>
        </div>
        </div>

        {/* earning analysis */}
        <EarningAnalysis data={info?.wallet} loading={isLoading} teamSize={info?.refStat?.teamSize} />

        {/* three generation data */}
        <GenerationData wallet={info?.wallet} refStat={info?.refStat} loading={isLoading} />
    </DashboardLayout>
  )
}

export default Team
