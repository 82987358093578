import React from 'react'

const Table:React.FC<TableProps> = ({ data }) => {
  return (
    <table className='custom_table'>
      <thead className='table_head'>
        <tr>
          <th>Currency / Name</th>
          <th>Latest Price</th>
          <th>24H Change</th>
        </tr>
      </thead>
      <tbody className='table_body'>
        {data?.map((item: any) => (
          <tr key={item?.id}>
            <td className='name'><img src={item?.img} alt='' /> 
              <section className='sub_name'>
                <span>{item?.symbol}</span>
                <span>{item?.subName}</span>
              </section>
            </td>
            <td className='price'>${item?.currentPrice}</td>
            <td className={`hChange ${item.percentageChange < 0 ? 'negative' : 'positive'}`}>{item?.percentageChange}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
