




import { userRequest } from '@/api/connect';
import { appStatus } from '@/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';



interface LevelState {
  level: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: LevelState = {
  level: null,
  isLoading: false,
  error: null,
};

const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    fetchLevelStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchLevelSuccess(state, action: PayloadAction<any, string>) {
      state.level = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchLevelFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchLevelStart, fetchLevelSuccess, fetchLevelFailure } = levelSlice.actions;

export const fetchUserLevel = () => async (dispatch: any) => {
  try {
    dispatch(fetchLevelStart());
    const response = await userRequest.get(`/user/fetch-levels`);
    const { data } = response.data
    dispatch(fetchLevelSuccess(data));
  } catch (error: any) {
    let errorMessage = appStatus.wrong; 
   if(error.response) {
    if (error.response.data.statusCode === 500) {
        errorMessage = appStatus.wrong;
      } else if (error.response.data.statusCode === 400) {
        errorMessage = error.response.data.message[0];
      } else {
          errorMessage = error.message || error.response.message || error.response.data.message
      }
   } else {
    errorMessage = error.message || error.response.message || error.response.data.message
   }
    dispatch(fetchLevelFailure(errorMessage));
  }
};

export default levelSlice.reducer;
