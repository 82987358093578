import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStatus } from '../../constants';
import { publicRequest } from '@/api/connect';
import { toast } from 'react-toastify';



interface UserDataProps {
    success: boolean;
    message: string;
    policy?: boolean;
}

interface RegisterState {
  isLoading: boolean;
  error: string | null;
  register: UserDataProps | null;
}

const initialState: RegisterState = {
  isLoading: false,
  error: null,
  register: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    registerStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    registerSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.register = action.payload;
    },
    registerFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { registerStart, registerSuccess, registerFailure } = registerSlice.actions;

export const registerUser = (countryCode: string, phoneNumber: string, invitationCode: string, password: string, confirmPassword: string, policy: boolean) => async (dispatch: any) => {
  try {
    dispatch(registerStart());
    const response = await publicRequest.put('', {countryCode, phoneNumber, invitationCode, password, confirmPassword, policy});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(registerSuccess(data));
      toast.success(message);
    } else {
      dispatch(registerFailure(message));
    }
  } catch (error: any) {
    if(error.code === "ECONNABORTED") {
      dispatch(registerFailure(appStatus.wrong));
    } else {
      dispatch(registerFailure(error?.message));
    }
   
  }
};

export default registerSlice.reducer;
