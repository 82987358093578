import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userRequest } from '@/api/connect';
import { toast } from 'react-toastify';



interface FundingDataProps {
    success: boolean;
    message: string;
}

interface RequestFundingState {
    isLoading: boolean;
    error: string | null;
    fundingRequest: FundingDataProps | null;
}

const initialState: RequestFundingState = {
    isLoading: false,
    error: null,
    fundingRequest: null,
};

const requestFundingSlice = createSlice({
    name: 'requestFunding',
    initialState,
    reducers: {
        requestFundingStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        requestFundingSuccess(state, action: PayloadAction<FundingDataProps>) {
            state.isLoading = false;
            state.error = null;
            state.fundingRequest = action.payload;
        },
        requestFundingFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const { requestFundingStart, requestFundingSuccess, requestFundingFailure } = requestFundingSlice.actions;

export const requestWalletFunding = (name: string, mainnet: string, amount: string, image: File, ) => async (dispatch: any) => {
    try {
        dispatch(requestFundingStart());
        const formData = new FormData();
        formData.append('name', name);
        formData.append('mainnet', mainnet);
        formData.append('amount', amount);
        formData.append('image', image);

        const response = await userRequest.post(`/user/request-walllet-funding`, formData);
        const { data, success, message } = response.data;
           if(success === true) {
            dispatch(requestFundingSuccess(data));
            toast.success(message);
           } else {
            dispatch(requestFundingFailure(message));
           }
    } catch (error: any) {
        let errorMessage = ""
        errorMessage = error.message
        dispatch(requestFundingFailure(errorMessage));
    }
};

export default requestFundingSlice.reducer;
