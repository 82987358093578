


import { userRequest } from '@/api/connect';
import { appStatus } from '@/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';



interface AddressState {
  info: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: AddressState = {
  info: null,
  isLoading: false,
  error: null,
};

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    fetchInfoStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchInfoSuccess(state, action: PayloadAction<any, string>) {
      state.info = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchInfoFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchInfoStart, fetchInfoSuccess, fetchInfoFailure } = infoSlice.actions;

export const fetchUserInfo = () => async (dispatch: any) => {
  try {
    dispatch(fetchInfoStart());
    const response = await userRequest.get(`/user/info`);
    const { data } = response.data
    dispatch(fetchInfoSuccess(data));
  } catch (error: any) {
    let errorMessage = appStatus.wrong; 
   if(error.response) {
    if (error.response.data.statusCode === 500) {
        errorMessage = appStatus.wrong;
      } else {
          errorMessage = error.message || error.response.message || error.response.data.message
      }
   } else {
    errorMessage = error.message || error.response.message || error.response.data.message
   }
    dispatch(fetchInfoFailure(errorMessage));
  }
};

export default infoSlice.reducer;
