import { RootState } from "@/config/store";
import { appService } from "@/services";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DeleteIcon, Edit } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, EmptyFile } from "../ui";
import { Skeleton } from "antd";
import { toast } from "react-toastify";
import AddAddress from "./Add_Address";
import EditAddress from "./Edit_Address";

const Wallet_Address = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { address, isLoading } = useSelector(
    (state: RootState) => state.address
  );
  const { err } = useSelector((state: RootState) => state.deleteAddress);
  const [addAddress, setAddAddress] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [ editAddressData, setEditAddressData] = useState<any>(null);
  const [ openEdit, setOpenEdit ] = useState<boolean>(false);
  const [ editSuccess, setEditSuccess ] = useState<boolean>(false);

  useEffect(() => {
    dispatch(appService.fetchAllAddress());
  }, [dispatch]);

  const handleDelete = async (id: number) => {
    dispatch(appService.deleteAddress(id));
  };

  const handleEdit = (id: number) => {
    const addressToEdit = address.find((item) => item.id === id);
    setEditAddressData(addressToEdit);
    setOpenEdit(true)
  };

  const handleEditSuccess = () => {
    setOpenEdit(false)
    setEditSuccess(true)
  }

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err]);

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  const handleSuccess = () => {
    setAddAddress(false);
    setSuccess(true);
  };

  return (
    <div>
      {isLoading ? (
          <>{addAddress || openEdit === true ? null : <Skeleton loading={isLoading} paragraph={{ rows: 3 }} />}</>
      ) : address?.length === 0 ? (
        <>
           {addAddress || openEdit === true ? null : (
             <div className="loading_state">
             <EmptyFile />
             <Button
              onClick={() => handleAddAddress()}
              variant="auth__btn deposit_fund_btn"
              color="primary"
              type="button"
              label="Add Wallet Address"
              loading={isLoading}
              disabled={isLoading}
  />
           </div>
           )}
            
        </>
      ) : (
        <>
          {addAddress || openEdit === true ? null : (
            <>
              {address?.map((item) => (
                <div key={item?.id} className="wallet_address_binding">
                  <section>
                    <span>{item?.walletAddress?.slice(0, 15)}</span>
                    <span>{item?.addressName}</span>
                  </section>
                  <span className="main_net">{item?.mainNet}</span>
                  <section className="address_binding_action">
                    <Button
                      shape="circle"
                      icon={<Edit size={15} color={`var(--app-gray)`} />}
                      onClick={() => handleEdit(item?.id)}
                    />
                    <Button
                      type="button"
                      shape="circle"
                      icon={<DeleteIcon size={15} color={`var(--app-white)`} />}
                      danger
                      onClick={() => handleDelete(item?.id)}
                    />
                  </section>
                </div>
              ))}
            </>
          )}

          {addAddress || openEdit === true ? null : (

            <Button
            onClick={() => handleAddAddress()}
            variant="auth__btn deposit_fund_btn"
            color="primary"
            type="button"
            label="Add Wallet Address"
            loading={isLoading}
            disabled={isLoading}
  />
          )}
        </>
      )}

      {addAddress && (
        <AddAddress
          success={success}
          onSuccess={handleSuccess}
          onClose={() => handleAddAddress()}
        />
      )}

      {openEdit && (
        <EditAddress 
          success={editSuccess}
          onSuccess={handleEditSuccess}
          onClose={() => handleEditSuccess()}
          walletAddress={editAddressData?.walletAddress} 
          mainNet={editAddressData?.mainNet}
          addressName={editAddressData?.addressName}
          id={editAddressData?.id}
        />
      )}
    </div>
  );
};

export default Wallet_Address;
