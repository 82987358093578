
import { formatAmount } from '@/utils/Utils'
import { Skeleton, Typography } from 'antd'
import { ChevronRight } from 'lucide-react'
import React from 'react'

interface WalletProps {
    data: any
    size: any
    loading: boolean
}

const AssetOverview:React.FC<WalletProps> = ({ data, size, loading }) => {
  return (
    <div className='asset_overview_wrapper'>
        <Typography className='assets_caption'>Asset Overview: <b>{loading ? <Skeleton.Button active={true}  size="small"/> : formatAmount(data?.flexibleBalance) ?? "0"} USDT</b></Typography>
        <div className='asset_container'>
            <section className='assets_level1'>
                <Typography>
                    <span>{loading ? <Skeleton.Button active={true}  size="small"/> : formatAmount(data?.totalRecharge) ?? "0"}</span>
                    <span>Total Recharge</span>
                </Typography>

                <Typography>
                    <span>{loading ? <Skeleton.Button active={true}  size="small"/> : formatAmount(data?.pendingFunds) ?? "0"}</span>
                    <span>Pending Funds <ChevronRight size={20} /></span>
                </Typography>
            </section>

            <section className='assets_level2'>
                <Typography>
                    <span>{loading ? <Skeleton.Button active={true}  size="small"/> : formatAmount(data?.todayEarnings) ?? "0"}</span>
                    <span>Total Earnings</span>
                </Typography>

                <Typography>
                    <span>{loading ? <Skeleton.Button active={true}  size="small"/> : formatAmount(data?.totalWithdrawal) ?? "0"}</span>
                    <span>Total Withdrawals</span>
                </Typography>

                <Typography>
                    <span>{loading ? <Skeleton.Button active={true}  size="small"/> : size?.teamSize}</span>
                    <span>Team Size</span>
                </Typography>
            </section>
        </div>
    </div>
  )
}

export default AssetOverview
