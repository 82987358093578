import { Images } from "@/assets/images";
import { ActivityTransactions, ChatBot, Deposit, Download, HomeHelp, InviteFriends, Withdraw } from "@/components";
import { paths } from "@/constants";



export const activities = [
    {
        img: Images.DepositIcon,
        label: "Deposit",
        content: <Deposit />,
        height: 400,
        path: null
    },
    {
        img: Images.WithdrawIcon,
        label: "Withdraw",
        content: <Withdraw />,
        height: 378,
        path: null
    },
    {
        img: Images.ActivityIcon,
        label: "Activity",
        content: <ActivityTransactions />,
        height: 378,
        path: null
    },

    {
        img: Images.TeamIcon,
        label: "Team",
        content: null,
        height: 378,
        path: paths.team
    },
    {
        img: Images.InviteIcon,
        label: "Invite",
        content: <InviteFriends />,
        height: '630px',
        path: null
    },
    {
        img: Images.DownloadIcon,
        label: "Download",
        content: <Download />,
        height: '600px',
        path: null
    },
    {
        img: Images.HelpIcon,
        label: "Help",
        content: <HomeHelp />,
        height: 600,
        path: null
    },
    {
        img: Images.CustomerIcon,
        label: "Support",
        content: <ChatBot />,
        height: 100,
        path: null
    },

]