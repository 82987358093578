import { Images } from "@/assets/images";
import { RootState } from "@/config/store";
import { appService } from "@/services";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyFile } from "../ui";

const Transactions = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { transactions, isLoading } = useSelector(
    (state: RootState) => state.transactions
  );

  useEffect(() => {
    dispatch(appService.fetchAllTransaction());
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <Skeleton loading={isLoading} paragraph={{ rows: 8 }} />
      ) : transactions?.length === 0 ? <EmptyFile text="No Transaction found" /> : (
        <>
          {transactions?.map((item) => (
            <section className="transactions" key={item?.reference}>
              <div className="trans_left">
                <img src={Images.ActivityIcon} alt="" width={30} height={30} />
                <section>
                  <span>{item?.type}</span>
                  <span>{item?.description}</span>
                </section>
              </div>
              <div className="amount_status">
                <span className="amount">{item?.amount} </span>
                <span className={`${item?.status}`}>{item?.status}</span>
              </div>
            </section>
          ))}
        </>
      )}
    </div>
  );
};

export default Transactions;
