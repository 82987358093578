
import { formatAmount } from '@/utils/Utils'
import { Skeleton, Typography } from 'antd'
import { ChevronRight } from 'lucide-react'
import React from 'react'

interface AssetsProps {
  flexibleBalance: string
  pendingFunds: string
  totalRecharge: string
}

interface Assets {
  wallet: AssetsProps
  loading: boolean
}

const Asset_Summary:React.FC<Assets> = ({wallet, loading}) => {


  return (
    <div className='asset_profile_wrapper'>
        <div className='assets_profile_container'>
            <Typography>Assets Overview (USDT)</Typography>
            <Typography>{loading ? <Skeleton.Button size='small' /> : formatAmount(wallet?.flexibleBalance) ?? "0"}</Typography>
        </div>
        <section className='assets_profile_record'>
                <Typography>        
                    <span>Total Recharge</span>
                    <span>{loading ? <Skeleton.Button size='small' /> : formatAmount(wallet?.totalRecharge) ?? "0"}</span>
                </Typography>

                <Typography>
                    <span>Pending Funds <ChevronRight color={`var(--app-asset-color)`} size={20} /></span>
                    <span>{loading ? <Skeleton.Button size='small' /> :  formatAmount(wallet?.pendingFunds) ?? "0"}</span>
                </Typography>
            </section>
    </div>
  )
}

export default Asset_Summary
