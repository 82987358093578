
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { publicRequest } from '@/api/connect';
import { toast } from 'react-toastify';
import { appStatus } from '@/constants';


interface UserDataProps {
    success: boolean;
    message: string
}

interface resetPasswordState {
  isLoading: boolean;
  error: string | null;
  resetPassword: UserDataProps | null;
}

const initialState: resetPasswordState = {
  isLoading: false,
  error: null,
  resetPassword: null,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    resetPasswordStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    resetPasswordSuccess(state, action: PayloadAction<UserDataProps>) {
      state.isLoading = false;
      state.error = null;
      state.resetPassword = action.payload;
    },
    resetPasswordFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetPasswordStart, resetPasswordSuccess, resetPasswordFailure } = resetPasswordSlice.actions;

export const resetPasswordUser = (otp: string, email: string, password: string, confirmPassword: string, ) => async (dispatch: any) => {
  try {
    dispatch(resetPasswordStart());
    const response = await publicRequest.post('/reset-password', {otp, email,  password, confirmPassword});
    const { data, success, message } = response.data
    if (success === true) {
      dispatch(resetPasswordSuccess(data));
      toast.success(message);
    } else {
      dispatch(resetPasswordFailure(message));
    }
  } catch (error: any) {
    if(error.code === 'ECONNABORTED') {
      dispatch(resetPasswordFailure(appStatus.wrong));
    } else {
      dispatch(resetPasswordFailure(error?.message));
    }
  }
};

export default resetPasswordSlice.reducer;
