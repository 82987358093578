import { Button } from "antd";
import React from "react";

const Btn: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      size="large"
      htmlType={props.type}
      type={props.color}
      disabled={props.disabled}
      className={props.variant}
      loading={props.loading}
      onClick={props.onClick}
      shape={props.shape}
      icon={props.icon}
      danger={props.danger}
    >
      {props.label}
    </Button>
  );
};

export default Btn;
