import { Input, Typography } from "antd";
import { OTPProps } from "antd/es/input/OTP";
import { Search } from "lucide-react";
import React from "react";

const Input_Field: React.FC<InputProps> = (props) => {
  return (
    <>
      {props.type === "text" ||
      props.type === "email" ||
      props.type === "file" ? (
        <div className="auth__input">
          <Typography.Text>{props.label}</Typography.Text>
          <section>
            {props.phoneCode ? (
              <input
                id="country__code"
                onClick={props.handleOpen}
                name={props.payloadName}
                value={props.countryCode}
                onChange={props.countryCodeChange}
                onBlur={props.countryBlur}
                autoFocus={props.autoFocus}
              />
            ) : null}
            <Input
              size={props.size}
              placeholder={props.placeholder}
              variant={props.variant}
              onChange={props.onChange}
              value={props.value}
              name={props.name}
              onBlur={props.onBlur}
              autoFocus={props.autoFocus}
              suffix={props.suffix}
              type={props.type}
              onClick={props.handleOpen}
              disabled={props.disabled}
            />
          </section>
        </div>
      ) : props.type === "password" ? (
        <div className="auth__input">
          <Typography.Text>{props.label}</Typography.Text>
          <Input.Password
            size={props.size}
            placeholder={props.placeholder}
            variant={props.variant}
            onChange={props.onChange}
            className="input__border"
            name={props.name}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus}
            suffix={props.suffix}
            onClick={props.handleOpen}
             disabled={props.disabled}
          />
        </div>
      ) : props.type === "search" ? (
        <div className="auth__input">
          <Input
            size={props.size}
            placeholder={props.placeholder}
            variant={props.variant}
            onChange={props.onChange}
            className="input__border"
            type={props.type}
            prefix={<Search />}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus}
            suffix={props.suffix}
            onClick={props.handleOpen}
             disabled={props.disabled}
          />
        </div>
      ) : (
        <div className="auth__input">
          <Input.OTP
            className="input__border"
            {...props as OTPProps}
          />
        </div>
      )}
    </>
  );
};

export default Input_Field;
