


import { userRequest } from '@/api/connect';
import { appStatus } from '@/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface WalletAddress {
  address: string;
  mainnet: string;
  uuid: string;
  name: string;
  id: number
}

interface AddressState {
  fundAddress: WalletAddress[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AddressState = {
  fundAddress: [],
  isLoading: false,
  error: null,
};

const fundAddressSlice = createSlice({
  name: 'fundAddress',
  initialState,
  reducers: {
    fetchAllFundingAddressStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchAllFundingAddressSuccess(state, action: PayloadAction<WalletAddress[]>) {
      state.fundAddress = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllFundingAddressFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAllFundingAddressStart, fetchAllFundingAddressSuccess, fetchAllFundingAddressFailure } = fundAddressSlice.actions;

export const fetchAllFundingAddress = () => async (dispatch: any) => {
  try {
    dispatch(fetchAllFundingAddressStart());
    const response = await userRequest.get(`/user/fetch-funding-walllet`);
    const { data } = response.data
    dispatch(fetchAllFundingAddressSuccess(data));
  } catch (error: any) {
    let errorMessage = appStatus.wrong; 
   if(error.response) {
    if (error.response.data.statusCode === 500) {
        errorMessage = appStatus.wrong;
      } else {
          errorMessage = error.message || error.response.message || error.response.data.message
      }
   } else {
    errorMessage = error.message || error.response.message || error.response.data.message
   }
    dispatch(fetchAllFundingAddressFailure(errorMessage));
  }
};

export default fundAddressSlice.reducer;
