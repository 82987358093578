




import { userRequest } from '@/api/connect';
import { appStatus } from '@/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appService } from '..';


interface CryptoProps {
  symbol: string;
  price: number;
  percentageChange: string;
}


interface AddressState {
  crypto: CryptoProps[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AddressState = {
  crypto: [],
  isLoading: false,
  error: null,
};

const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    fetchCryptoStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchCryptoSuccess(state, action: PayloadAction<CryptoProps[]>) {
      state.crypto = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchCryptoFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchCryptoStart, fetchCryptoSuccess, fetchCryptoFailure } = cryptoSlice.actions;

export const fetchUserCrypto = () => async (dispatch: any) => {
  try {
    dispatch(fetchCryptoStart());
    const response = await userRequest.get(`/user/market`);
    const { data } = response.data
    dispatch(fetchCryptoSuccess(data));
  } catch (error: any) {
    if(error?.response.data.statusCode === 401) {
      dispatch(appService.logoutUser());
    } else if (error.code === "ECONNREFUSED" || error.code === "ECONNABORTED") {
    dispatch(fetchCryptoFailure(appStatus.wrong));
  } else {
    dispatch(fetchCryptoFailure(error.message));
  }
};
}

export default cryptoSlice.reducer;
